<template>
  <main>
		<section class="layout">
			<div class="container-fluid">
				<div class="row">
					<div class="col-lg-12">
						<div class="admin-title">
							<h3>Upraviť akciu</h3>
						</div>
					</div>
					<div class="col-lg-12">
						<div class="add-form">
							<form @submit.prevent="updateAction">
								<div class="row">
									<div class="col-lg-12">
										<p>Slug (Povinné)</p>
										<label for="slug">
											<input id="slug" type="text" v-model="slug" required placeholder="Slug">
										</label>
										<p>Názov akcie (Nepovinné)</p>
										<label for="title">
											<input id="title" type="text" v-model="title" placeholder="Titulok">
										</label>
										<p>Popis (Nepovinné)</p>
										<label for="description">
											<textarea id="description" type="text" v-model="description" placeholder="Popis"></textarea>
										</label>
									</div>
								</div>
								<button type="submit" class="add-btn">Upraviť</button>
								<router-link class="back-btn" :to="{name: 'Akcia'}">Späť</router-link>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import db from '../../Firebase/firebaseInit'
export default {
	name: 'Edit',
	data(){
		return {
			slug: null,
			title: null,
			description: null,
		}
	},
	 beforeRouteEnter(to, from, next){
		db.collection('akcie')
		.where('slug', '==', to.params.id)
		.get()
		.then(querySnaphost =>{
				querySnaphost.forEach(doc => {
					next(vm =>{
						vm.slug = doc.data().slug
						vm.title = doc.data().title
						vm.description = doc.data().description
					})
				})
			})
	},
	watch: {
		'$route':'fetchData'
	},
	methods: {
		fetchData (){
			db.collection('akcie')
			.where('slug', '==', this.$route.params.id)
			.get()
			.then(querySnaphost => {
				querySnaphost.forEach(doc => {
					this.slug =  doc.data().slug
					this.title =  doc.data().title
					this.description =  doc.data().description
				})
			})
		},
		updateAction(){
			db.collection('akcie')
			.where('slug', '==', this.$route.params.id)
			.get()
			.then(querySnaphost => {
				querySnaphost.forEach(doc => {
					doc.ref.update({
						slug: this.slug,
						title: this.title,
						description: this.description,
					})
					.then(() => {
						this.$router.push('/admin/akcia')
					})
				})
			})
		},
	}
}
</script>

<style lang="scss">
@import "../../assets/scss/_variables";
@import "../../assets/scss/_mixins";


</style>