<template>
	<section class="mobile-menu">
		<section class="sidemenu">
			<button class="sidemenu__btn" v-on:click="navOpen=!navOpen" v-bind:class="{active:navOpen}">
				<span class="top"></span>
				<span class="mid"></span>
				<span class="bottom"></span>
			</button>
			<transition name="scale">
				<nav v-show="navOpen">
					<div class="sidemenu__wrapper">
						<ul class="sidemenu__list">
							<li>
								<router-link :to="{name: 'Home'}">Domov</router-link>
							</li>
							<li>
								<router-link :to="{name: 'About'}">O nás</router-link>
							</li>
							<li>
								<router-link :to="{name: 'Products'}">Produkty</router-link>
							</li>
							<li>
								<router-link :to="{name: 'Realisations'}">Naše práce</router-link>
							</li>
							<li>
								<router-link :to="{name: 'Pricelist'}">Cenník</router-link>
							</li>
							<li>
								<router-link :to="{name: 'Contact'}">Kontakt</router-link>
							</li>
						</ul>
					</div>
				</nav>
			</transition>
		</section>
    </section>
</template>

<script>
export default {
	data: () => {
		return{
			navOpen: false,
		}
	},
  watch: {
    '$route' () {
      this.navOpen = false
    }
  }
}
</script>

<style lang="scss" >
@import "../../assets/scss/_variables";
@import "../../assets/scss/_mixins";

.mobile-menu{
	text-align: center;
	@media #{$small}{
		display: none;
	}
	.sidemenu {
		nav {
			width: 100%;
			height: 100%;
			background: rgba($primary, 0.8);
			position: fixed;
			top: 0;
			right: 0;
			z-index: 5;
		}
		.sidemenu {
			&__btn {
				display: block;
				width: 50px;
				height: 50px;
				background: $secondary;
				border: none;
				position: relative;
				z-index: 100;
				appearance: none;
				cursor: pointer;
				outline: none;
				margin: 0 auto !important;
				span {
					display: block;
					width: 20px;
					height: 2px;
					margin: auto;
					background: $primary;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					transition: all .4s ease;
					&.top {
						transform: translateY(-8px);
					}
					&.bottom {
						transform: translateY(8px);
					}
				}
				&.active{
					@media #{$very_small}{
						transform: translate(110px, -100px);
						transition: 0.35s ease;
					}
					.top {
						transform: rotate(-45deg);
					}
					.mid{
						transform: translateX(-20px) rotate(360deg);
						opacity: 0;
					}
					.bottom {
						transform: rotate(45deg);
					}
				}
			}
			&__wrapper {
				height: 100%;
				position: relative;
			}
			&__list {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 24px;
				width: 100%;
				li{
					display: block;
					a{
						color: $white !important;
					}
				}
				.router-link-exact-active{
					color: $secondary !important;
					font-weight: 700;
					text-decoration: underline !important;
				}
			}
		}
	}
}

.scale-enter{
	transform:scale(100);
	opacity: 0;
}

.scale-enter-active,.scale-leave-active{
	transition:0.35s ease;
}

.scale-leave-to{
	transform: scale(100);
	opacity: 0;
}

</style>