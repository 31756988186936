import Vue from 'vue'
import firebase from 'firebase/app'
import Swal from 'sweetalert2'
import router from '../../router/index'

const state = {
    isLoggedIn: false,
    currentUser: false,
}
const getters = {
    isLoggedIn: state => state.isLoggedIn,
    currentUser: state => state.currentUser
}
const actions = {
   current({commit}){
    if(firebase.auth().currentUser) {
        const verification =
            state.isLoggedIn = true;
            state.currentUser = firebase.auth().currentUser.email;
            commit('setcurrent', verification)
        }
    } ,
    logout({commit}) {
        const logout=  firebase.auth().signOut()
        .then( () => {
            Swal.fire({
            title: 'Info!',
            text: `Používateľ ${state.currentUser} odhlásený`,
            icon: 'info',
            })
        .then()
                setTimeout( ()=> router.go({name: router.path}), 2500)
        });
        commit('setLogout', logout)
    },
   
}
const mutations = {
    setcurrent (state, login){
        Vue.set(state, 'login', login)
    },
    setLogout (state, logout){
        Vue.set(state, 'logout', logout)
    },
}

export default{
    state, 
    getters, 
    actions, 
    mutations
}