<template>
    <section class="header-bottom p-84">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-3 col-md-5 col-sm-5">
                    <div class="logo">
                        <img src="../../assets/img/logo.jpg" alt="Logo" title="Logo">
                    </div>
                </div>
                <div class="col-lg-9 col-md-7 col-sm-7">
                    <div class="contact">
                        <div class="social">
                            <a href="https://www.facebook.com/kamenarstvogelkam" title="Facebook" target="_blank" rel="noopener noreferrer">
                                <fbIcon/>
                            </a>
                            <a href="mailto:gelkamsro@gmail.com" title="Poslať email">
                                <mailIcon/>
                            </a>
                        </div>
                        <div class="phone-numbers" @click="showPhonenumbers" >
                            <phoneIcon/>
                        </div>
                        <transition name="fade" >
                            <section class="numbers" v-if="showNumbers">
                                <div class="close-btn" @click="closePhonenumbers"> 
                                    <closeIcon/>
                                </div>
                                <h5>Telefonický kontakt:</h5>
                                <a href="tel:0911249267">0911 249 267</a>
                                <a href="tel:0534411493">053/44 11 493</a>
                                <a href="tel:0903214208">0903 214 208</a>
                                <a href="tel:0904397269">0904 397 269</a>
                            </section>
                        </transition>
                        <div class="openings-hours" @click="showOpeningshours">
                            <clockIcon/>
                        </div>
                        <transition name="fade" >
                            <section class="openings" v-if="showOpenings">
                                <div class="close-btn" @click="closeOpeningshours"> 
                                    <closeIcon/>
                                </div>
                                <h5>Otváracie hodiny:</h5>
                                <p>Po: 07:00 - 16:00</p>
                                <p>Ut: 07:00 - 16:00</p>
                                <p>St: 07:00 - 16:00</p>
                                <p>Št: 07:00 - 16:00</p>
                                <p>Pi: 07:00 - 16:00</p>
                                <p>So: Po telefonickej dohode</p>
                                <p>Ne: Po telefonickej dohode</p>
                            </section>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import phoneIcon from '../../assets/icons/phone-solid.svg'
import mailIcon from '../../assets/icons/envelope-regular.svg'
import fbIcon from '../../assets/icons/facebook-square-brands.svg'
import clockIcon from '../../assets/icons/clock-regular.svg'
import closeIcon from '../../assets/icons/times-solid.svg'
export default {
    name: 'HeaderBottom', 
    components:{
        mailIcon, phoneIcon, fbIcon, clockIcon, closeIcon
    },
    data: () => {
        return{
            showNumbers: false,
            showOpenings: false
        }
    },
    methods:{
        showPhonenumbers(){
            this.showNumbers = !this.showNumbers
        },
        closePhonenumbers(){
            this.showNumbers = false
        },
        showOpeningshours(){
            this.showOpenings = !this.showOpenings
        },
        closeOpeningshours(){
            this.showOpenings = false
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/scss/_variables";
@import "../../assets/scss/_mixins";

.header-bottom{
    border-bottom: 2px solid $primary;
    .logo{
        animation: logo 0.35s linear; 
        img{
            width: 100%;
            height: auto;
            margin-left: -16%;
            @media #{$xs}{
                width: 70%;
                margin-left: 0;
            }
        }
    }
    .contact{
        animation: contact 0.35s linear;
        text-align: right;
        @media #{$xs}{
              text-align: center;
              margin-top: 12px;
            }
        .social, .phone-numbers, .openings-hours{
            display: inline-block;
            cursor: pointer;
            svg{
                width: auto;
                height: 20px;
                color: #7B7B7B;
                margin-left: 12px;
                pointer-events: fill;
                &:hover{
                    color: $secondary;
                    transition: 0.35s ease;
                }
                &:focus{
                    color: $secondary;
                    transition: 0.35s ease;
                }
            }
            .fa,.fa-facebook-square{
                margin-left: 0;
            }
        }
        .phone-numbers,.openings-hours{
            display: inline-block;
            position: relative;
            cursor: pointer;
            background: $white;
        }
        .numbers,.openings{
            position: absolute;
            right: 0;
            z-index: 998;
            border: 2px solid $primary;
            background: $white;
            padding: 24px 48px;
            text-align: left;
            @media #{$xs}{
              right: 50%;
              transform: translate(50%);
            }
            .close-btn{
                position: inherit;
                right: 12px;
                top: 4px;
                svg{
                   cursor: pointer;
                   font-size: 25px;
                   color: $primary;
                   
                }
            }
            h5{
                margin-bottom: 12px !important;
            }
        }
        .numbers{
            a{
                display: block;
                &:hover{
                    color: $secondary !important;
                    transition: 0.35s ease;
                }
            }
        }
    }
}

@keyframes contact {
        0%{
          transform: translateX(100px);
          opacity: 0;
        }
        50%{
          opacity: 0;
        }
        75%{
          opacity: 1;
        }
        100%{
          transform: translateX(0px);
          opacity: 1;
        }
      }
@keyframes logo {
        0%{
          transform: translateX(-100px);
          opacity: 0;
        }
        50%{
          opacity: 0;
        }
        75%{
          opacity: 1;
        }
        100%{
          transform: translateX(0px);
          opacity: 1;
        }
      }

// phone number and openings ours animations
.fade-enter {
  opacity: 0;
  transition: 0.5s all ease;
}

.fade-enter-to {
  opacity: 1;
  transition: 0.5s all ease;
}

.fade-leave-to {
  opacity: 0;
  transition: 0.5s all ease;
}
</style>