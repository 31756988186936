<template>
  <section v-scrollanimation class="feedback p-84 mt-68" v-if="allFeedback.length">
	  <div class="container-fluid">
		  <div class="row justify-content-center">
			<div class="col-lg-12">
				<div class="section-title">
					<h2>Povedali o nás</h2>
				</div>
			</div>
			<div class="col-lg-12">
				<agile :infinite="true" :autoplay="true" :autoplay-speed="5000" :dots="true" >
					<div   v-for="(feedback, index) in allFeedback" :key="index.id">
						<div class="slide">
							<p>{{feedback.feedback}}</p>
							<h5>{{feedback.name}}</h5>
						</div>
					</div>
					<template slot="prevButton" name="prev"><leftIcon/></template>
    				<template slot="nextButton" name="next"><rightIcon/></template>
				</agile>
			</div>
		  </div>
	  </div>
  </section>
</template>

<script>
import { VueAgile } from 'vue-agile'
import {mapGetters, mapActions} from 'vuex'
import leftIcon from '../assets/icons/angle-left-solid.svg'
import rightIcon from '../assets/icons/angle-right-solid.svg'
export default {
	name: 'Feedback',
	components: {
        agile: VueAgile,
		leftIcon, rightIcon,
    },
	computed:{
    	...mapGetters(['allFeedback']),
		
  	},
	created (){
		if (this.getFeedback.length < 1) {
			this.getFeedback()
		}
		
	},
	methods: {
		...mapActions(['getFeedback']),
    },
}
</script>

<style lang="scss">
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";

.feedback{
	.section-title{
		@include section-header;
		margin-bottom: 24px !important;
	}
	.quote{
		color: $primary;
		margin-bottom: 24px;
		svg{
			font-size: 70px;
			color: $white;
			stroke: $primary;
  			stroke-width: 15;
		}
	}
	.agile{
		color: $primary;
		.slide{
			h5{
				font-size: 16px;
				font-weight: 700;
				margin-bottom: 12px !important;
			}
			p{
				margin-bottom: 12px !important;
				padding: 0 68px;
			}
		}	
		.agile__actions{
			justify-content: center !important;
			.agile__dot{
				margin-left: 4px;
				&:first-child{
					margin-left: 0px;
				}
				button{
					width: 15px;
					height: 15px;
					border-radius: 50%;
					border: 2px solid $secondary;
					background: none;
					outline: none;
				}
			}
			.agile__dot--current{
				background: $secondary;
				border-radius: 50%;
			}
			.agile__nav-button{
				width: 30px;
				height: 30px;
				border-radius: 50%;
				border: 2px solid $secondary;
				background: $white;
				outline: none;
				svg{
					color: $secondary;
				}
				.fa-chevron-right{
					margin-left: 1px !important;
					margin-top: 4px !important;
				}
				.fa-chevron-left{
					margin-left: -1px !important;
					margin-top: 4px !important;
				}
			}
			.agile__nav-button--prev{
				position: absolute;
				top: 10%;
				left: 0;
				z-index: 15;
				@media #{$xs}{
					top: 40%;
				}
			}
        	.agile__nav-button--next{
				position: absolute;
				top: 10%;
				right: 0;
				z-index: 15;
				@media #{$xs}{
					top: 40%;
				}
			}
		}
	}
}
</style>