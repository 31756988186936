<template>
  <section v-scrollanimation class="aboutus p-84 mt-68">
      <div class="container-fluid">
          <div class="row">
              <div class="col-lg-12">
                  <article>
                    <p>Sme kamenárstvo v Gelnici s viac ako <b>15-ročnou praxou,</b> ktoré vzniklo ako prirodzený nástupca spoločnosti Ján Vrabeľ Kamenárstvo, pôsobiacej na trhu od roku 2001. Hlavným predmetom našej činnosti je <b>kvalitná kamenárska práca</b> zahŕňajúca <b>komplexné spracovanie prírodného kameňa,</b> ako aj výrobu produktov z betónu a kameniny.</p>
                    <p>V cintorínskej architektúre sa venujeme výrobe a montáži <b>náhrobných kameňov, najmä jednopomníkov, dvojpomníkov a žulových urnových hrobov.</b>  V stavebnej architektúre ponúkame na mieru vyrobené <b>kuchynské pracovné dosky, vibroliate betónové výrobky</b> a ďalšie betonárske práce.</p>
                    <p>Našim zákazníkom sme k dispozícií <b>sedem dní v týždni</b> a ochotne sa snažíme vyhovieť ich individuálnym požiadavkám. </p>
                  </article>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
export default {
    name: 'Aboutus'
}
</script>

<style lang="scss">
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";

.aboutus{
    p{
        margin-bottom: 24px !important;
        font-size: 18px;
    }
}
</style>