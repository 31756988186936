<template>
  <div >
    <Header v-if="!showNavigation"/>
    <router-view/>
    <Footer v-if="!showNavigation"/>
  </div>
</template>

<script>
import Header from './components/header/Header'
import Footer from './components/Footer'

export default {
  name: 'App',
  components:{
    Header, Footer
  },
  data: () =>{
    return{
      showNavigation: null,
    }
  },
  created(){
    this.checkRoute()
  },
  methods:{
    //hide Header and Footer component in this case
    checkRoute(){
      if(
        this.$route.name === 'Admin' || 
        this.$route.name === 'AdminUvod' || 
        this.$route.name === 'Akcia' || 
        this.$route.name === 'PridatAkcia' || 
        this.$route.name === 'UpravitAkcia' || 
        this.$route.name === 'UpravitZula' || 
        this.$route.name === 'UpravitJednopomnik' || 
        this.$route.name === 'UpravitDvojpomnik' || 
        this.$route.name === 'UpravitNamieru' || 
        this.$route.name === 'UpravitDomacnost' ||
        this.$route.name === 'Recenzie' ||
        this.$route.name === 'PridatRecenziu' ||
        this.$route.name === 'UpravitRecenziu' ||
        this.$route.name === 'PoslednePrace' 
        ){
        this.showNavigation = true
        return
      }
      this.showNavigation = false
    }
  },
  watch:{
    $route(){
      this.checkRoute()
    }
  }
}
</script>


<style lang="scss">
@import "./assets/scss/_variables";
@import "./assets/scss/_general";
@import "./assets/scss/_mixins";


</style>
