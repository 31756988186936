<template>
	<main>
		<section class="admin">
			<aside class="admin-nav">
				<nav>
					<section class="user-info">
						<h3>Admin panel</h3>
						<p>Prihlásený používateľ:</p>
						<p v-if="isLoggedIn">{{currentUser}}</p>
					</section>
					<ul>
						<li>
							<router-link to="/admin/uvod" style="margin-left:0px !important">
								<penIcon/>
								<p>Užívateľská príručka</p>
							</router-link>
						</li>
						<li>
							<router-link to="/admin/akcia" style="margin-left:0px !important">
								<penIcon/>
								<p>Akciové ponuky</p>
							</router-link>
						</li>
						<li>
							<router-link to="/admin/upravit-zula" style="margin-left:0px !important">
								<penIcon/>
								<p>Žulové urnové hroby</p>
							</router-link>
						</li>
						<li>
							<router-link to="/admin/upravit-jednopomnik" style="margin-left:0px !important">
								<penIcon/>
								<p>Jednopomníky</p>
							</router-link>
						</li>
						<li>
							<router-link to="/admin/upravit-dvojpomnik" style="margin-left:0px !important">
								<penIcon/>
								<p>Dvojpomníky</p>
							</router-link>
						</li>
						<li>
							<router-link to="/admin/upravit-namieru" style="margin-left:0px !important">
								<penIcon/>
								<p>Hroby na mieru</p>
							</router-link>
						</li>
						<li>
							<router-link to="/admin/upravit-domacnost" style="margin-left:0px !important">
								<penIcon/>
								<p>Domácnosť</p>
							</router-link>
						</li>
						<li>
							<router-link to="/admin/recenzie" style="margin-left:0px !important">
								<penIcon/>
								<p>Recenzie</p>
							</router-link>
						</li>
						<li>
							<router-link to="/admin/posledne-prace" style="margin-left:0px !important">
								<penIcon/>
								<p>Posledné práce</p>
							</router-link>
						</li>
					</ul>
					<h5>Navigácia:</h5>
					<ul class="side-nav">
						<li>
							<router-link :to="{name: 'Home'}">Domov</router-link>
						</li>
						<li>
							<router-link :to="{name: 'About'}">O nás</router-link>
						</li>
						<li>
							<router-link :to="{name: 'Products'}">Produkty</router-link>
						</li>
						<li>
							<router-link :to="{name: 'Realisations'}">Naše práce</router-link>
						</li>
						<li>
							<router-link :to="{name: 'Pricelist'}">Cenník</router-link>
						</li>
						<li>
							<router-link :to="{name: 'Contact'}">Kontakt</router-link>
						</li>
					</ul>
					<button v-if="isLoggedIn" class="logout" @click="logout"> Odhlásiť sa<singoutIcon/></button>
				</nav>
			</aside>
		</section>
		<AdminSidemenu/>
		<router-view/>
	</main>
</template>

<script>
import AdminSidemenu from '../components/admin/AdminSidemenu'
import penIcon from '../assets/icons/pen-solid.svg'
import singoutIcon from '../assets/icons/sign-out-alt-solid.svg'
import { mapActions, mapGetters } from 'vuex'
export default{
	name: 'Admin',
	components: {
		AdminSidemenu, penIcon, singoutIcon
	},
	computed:{
    	...mapGetters(['isLoggedIn', 'currentUser'])
 	},
	created(){
		this.current()
	},
	methods: {
		...mapActions(['current', 'logout']),
		
	}
}
</script>

<style lang="scss">
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";

.admin{
	display: flex;
	.admin-nav{
		overflow-y: scroll;
		text-align: left;
		background: linear-gradient(to top, $secondary, $primary);
		width: 25%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index:5;
		color: $secondary;
		@media #{$xxl}{
			width: 17%;
		}
		@media #{$mobile}{
			display: none;
		}
		.user-info{
			padding: 12px 12px;
			color: $white;
		}
		ul{
			li{
				width: 100%;
				&:hover{
					background: lighten($primary, 10%);
				}
				&:focus{
					background: lighten($primary, 10%);
				}
				a{
					display: flex;
					align-items: center;
					padding: 6px 0;
					svg{
						color: $white;
						margin:0 8px;
					}
				}
				p{
					color: $white !important;
				}
			}
			.router-link-exact-active{
				background: lighten($primary, 10%);
			}
		}
		h5{
			color: $white;
			margin-left: 12px;
		}
		.side-nav{
			@include admin-side-list;
		}
		button{
			@include buttons;
			margin: 0 0 12px 12px;
			background: $white;
			border: none;
			outline: none;
			svg{
				margin-left: 12px;
			}
			&:hover{
				border: none;
				background: $white;
				color: $secondary !important;
				transition: 0.35s ease;
			}
			&:focus{
				border: none;
				background: $white;
				color: $secondary !important;
				transition: 0.35s ease;
			}
		}
	}
}
</style>