<template>
    <article class="layout">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="admin-title">
                        <h2>Úvod - užívateľská príručka.</h2>
                    </div>
                    <p>V jednotlivých kategóriách (akciové ponuky, žulové urnové hroby, jednopomníky,...) si viete pridávať svoj vlastný obsah.</p>
                    <ol class="manual">
                        <li>V časti s názvom <b>Akcia</b> si viete pridať jednu alebo viac akcií.</li>
                        <ul>
                            <li>Pre pridanie akcie je potrebné kliknúť na tlačidlo <b>PRIDAŤ AKCIU</b> v časti AKCIOVÉ PONUKY a vyplniť jednoduchý formulár.</li>
                            <li>
                                <p>Jediné <b>povinné</b> políčko je <b>slug.</b> Je potrebné ho vyplniť, aby pri zmene alebo zmazaní položky vedela aplikácia správne určiť položku, ktorá sa má <b>upraviť/zmazať.</b></p>
                                <p><b>Slug</b> musí byť <b><u>vyplnený vždy malými písmenami bez diakritiky.</u></b></p>
                                <p>Ostatné polia sú nepovinné a môžu byť vyplnené ľubovoľne. <b>Polia, ktoré nebudú vyplnené sa na stránku nevypíšu.</b></p>
                                <p>Akciovú ponuku po vyplnení formulára zverejníte tlačidlom <b>Uverejniť.</b></p>
                            </li>
                            <li>Pre zmenu alebo doplnenie informácií k danej akciovej ponuke je potrebné kliknúť na <b>ikonu pera.</b></li>
                            <li><b>Požadované zmeny sa robia zmenou údajov v jednotlivých poliach,</b> ktoré sú načítané a predvyplnené z databázy a zmena sa odzrkadlí po stlačení tlačidla <b>Upraviť.</b></li>
                        </ul>
                        <li><b>Rovnaké podmienky</b> pridania platia aj pre <b>RECENZIE</b> s tým rozdielom, že <b>všetky polia</b> vo formulári <b>sú povinné.</b></li>
                        <li>V ostatných častiach <b>(žulové urnové hroby, jednopomníky, dvojpomníky, hroby na mieru, domácnosť a posledné práce)</b> si viete pridať vlastné referencie vo forme fotiek.</li>
                        <ul>
                            <li>Nahrávanie fotiek je plne automatické. Stačí vybrať danú fotku pomocou tlačidla <b>NAHRAŤ FOTKU</b> a fotka sa automaticky sama nahrá a zobrazí v aplikácií.</li>
                            <li>
                                <p><b>Každú fotku je potrebné pred nahratím do databázy upraviť, tak aby šírka (width) mala 1200px a výška (height) mala hodnotu 900px, inak môže dôjsť k nesprávnemu zobrazeniu fotiek v aplikácii.</b></p>
                                <p>Na to slúži online nástroj <a href="https://online-image-resizer.com">https://online-image-resizer.com</a></p>
                                <p>Zároveň je potrebné vykonať aj kompresiu fotky pomocou <a href="https://tinyjpg.com">https://tinyjpg.com</a> a následne môže byť fotka pridaná do databázy.</p>
                                <p><b>!FOTKY FOŤTE VŽDY NA ŠÍRKU!</b></p>
                            </li>
                            <li>Na vymazanie fotky slúži ikona SMETNÉHO KOŠA a následne potvrdenie alebo stornovanie daného úkonu.</li>
                            <li><b>DÔLEŽITÉ: Je potrebné venovať pozornosť vyššie uvedenému návodu ohľadom pridávania fotiek, vynechanie ktoréhokoľvek vyššie spomenutého kroku povedie k rýchlemu zaplneniu databázy a násobnému spomaleniu webovej stránky. </b></li>
                        </ul>
                    </ol>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
export default {

}
</script>

<style lang="scss">
@import "../../assets/scss/_variables";
@import "../../assets/scss/_mixins";
.layout{
    .admin-title{
        h2{
            @include section-header;
            margin-bottom: 12px !important;
        }
    }
    .manual{
        ul{
            list-style: disc !important;
            padding-left: 40px !important;
            li{
                display: list-item !important;
                p{
                    a{
                        color: $primary !important;
                    }
                }
            }
        }
    }
}
</style>