<template>
	<footer>
		<section v-scrollanimation class="footer mt-68 p-84">
			<div class="container-fluid">
				<div class="row align-items-baseline">
					<div class="col-lg-3 col-md-6 col-sm-6">
						<div class="logo">
							<h2>Gelkam, s.r.o.</h2>
						</div>
						<div class="footer-text">
							<p>Komplexné spracovanie prírodného kameňa.</p>
						</div>
						<div class="footer-social">
                            <a href="https://www.facebook.com/kamenarstvogelkam" title="Facebook" target="_blank" rel="noopener noreferrer">
                                <fbIcon/>
                            </a>
                            <a href="mailto:gelkamsro@gmail.com" title="Poslať email">
                                <mailIcon/>
                            </a>
						</div>
					</div>
					<div class="col-lg-2 col-md-6 col-sm-6">
						<div class="footer-title">
							<h6>Odkazy</h6>
						</div>
						<div class="footer-links">
							<ul class="nav-bar">
								<li>
									<router-link :to="{name: 'Home'}">Domov</router-link>
								</li>
								<li>
									<router-link :to="{name: 'About'}">O nás</router-link>
								</li>
								<li>
									<router-link :to="{name: 'Products'}">Produkty</router-link>
								</li>
								<li>
									<router-link :to="{name: 'Realisations'}">Naše práce</router-link>
								</li>
								<li>
									<router-link :to="{name: 'Pricelist'}">Cenník</router-link>
								</li>
								<li>
									<router-link :to="{name: 'Contact'}">Kontakt</router-link>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 col-sm-6">
						<div class="footer-title">
							<h6>Kontaktné informácie</h6>
						</div>
						<div class="footer-contact">
							<ul>
								<li>
									<mailIcon/>
									<a href="mailto:gelkamsro@gmail.com" title="Poslať email">gelkamsro@gmail.com</a>
								</li>
								<li>
									<phoneIcon/>
									<a href="tel:0911249267">0911 249 267</a>
								</li>
								<li>
									<phoneIcon/>
									<a href="tel:0534411493">053/44 11 493</a>
								</li>
								<li>
									<phoneIcon/>
									<a href="tel:0903214208">0903 214 208</a>
								</li>
								<li>
									<phoneIcon/>
									<a href="tel:0904397269">0904 397 269</a>
								</li>
								
							</ul>
						</div>
					</div>
					<div class="col-lg-4 col-md-6 col-sm-6">
						<div class="footer-title">
							<h6>Kde nás nájdete</h6>
						</div>
						<div class="footer-openings">
							<ul>
								<li>
									<mapIcon/>
									<a href="https://goo.gl/maps/tMAkWtWYpqQusnwm9" title="Adresa" target="_blank" rel="noopener noreferrer">Slovenská 13, 056 01 Gelnica</a>
								</li>
								<li class="flex">
									<clockIcon/>
									<div>
										<p>Po-Pia: 07:00 - 16:00</p>
										<p>So-Ne: Po tel. dohode</p>
									</div>
								</li>
								<li>
									<iframe title="Adresa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1103.7803097635265!2d20.934319284018148!3d48.853504095480005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473ef9201513ed9f%3A0x7b337e68166a0243!2sSlovensk%C3%A1%2013%2C%20056%2001%20Gelnica!5e0!3m2!1ssk!2ssk!4v1630483015433!5m2!1ssk!2ssk" width="100%" height="236px" ></iframe>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-12">
						<div class="footer-end">
							<p>&copy; 2005 - {{ new Date().getFullYear() }} kamenarstvo-gelkam.sk - Komplexné spracovanie prírodného kameňa. Vytvoril <a href="https://www.webux.sk" title="Webux" rel="noreferrer noopener" target="_blank">webux.sk</a></p>
						</div>
					</div>
				</div>
			</div>
		</section>
	</footer>
</template>

<script>
import phoneIcon from '../assets/icons/phone-solid.svg'
import mailIcon from '../assets/icons/envelope-regular.svg'
import fbIcon from '../assets/icons/facebook-square-brands.svg'
import clockIcon from '../assets/icons/clock-regular.svg'
import mapIcon from '../assets/icons/map-marker-alt-solid.svg'
export default {
	name: 'Footer',
	components:{
        mailIcon, phoneIcon, fbIcon, clockIcon, mapIcon
    },
}
</script>

<style lang="scss">
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";

.footer{
	background: $gradient;
	text-align: left;
	padding-top: 66px !important;
	position: relative;
	color: $white;
	&::before{
		content: '';
		background-image: url(../assets/img/rippedtop.png);
		background-repeat: no-repeat;
		position: absolute !important;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
	}
	@media #{$mobile}{
		text-align: center;
	}
	.container-fluid{
		position: inherit;
		z-index: 11;
	}
	.logo{
		h2{
			font-size: 30px;
			font-weight: 700;
		}
	}
	.footer-text{
		font-size: 18px;
		padding: 24px 48px 24px 0;
		@media #{$mobile}{
			padding: 24px 0 24px 0;
		}
	}
	.footer-social{
		a{
			margin-left: 12px;
			&:first-child{
				margin-left: 0px;
			}
			svg{
				width: auto;
                height: 20px;
				color: $white;
				&:hover{
					color: $secondary;
					transition: 0.35s ease;
				}
			}
		}
	}
	.footer-title{
		h6{
			font-size: 20px;
			margin-bottom: 30px !important;
		}
	}
	.footer-links{
		.nav-bar{
			li{
				display: block !important;
				margin-bottom: 12px !important;
				a{
					@include footer-links;
				}
			}
		}
	}
	.footer-contact{
		li{
			display: block !important;
			margin-top: 16px;
			&:first-child{
				margin-top: 0px;
			}
			a{
				@include footer-links;
				margin-left: 12px;
			}
		}
	}
	.footer-openings{
		ul{
			li{
				margin-bottom: 12px;
				svg{
					
					margin-right: 12px;
				}
				a{
					@include footer-links;
					margin-left: 4px;
					@media #{$mobile}{
						margin-left: 0px;
					}
				}
			}
			.flex{
				display: flex;
				align-items: center;
				@media #{$mobile}{
					justify-content: center;
				}
			}
		}
	}
	.footer-end{
		text-align: center;
		margin-top: 24px;
		p{
			margin-bottom: 24px !important;
			a{
				@include footer-links;
			}
		}
	}
}
</style>