<template>
  	<section class="layout">
		<div class="container-fluid">
			<div class="row">
				<div class="col-lg-12">
					<section class="edit-title">
						<h2>Úprava žulových urien</h2>
					</section>
					<section class="form">
						<form>
							<div class="fileUpload">
								<input type="file" class="upload" required ref="imgName" @change="onUploadZula">
								<span>Nahrať fotku</span>
							</div>
						</form>
						<section class="progres">
							<p>Nahrávam: {{uploadZula.toFixed()+"%"}}</p>
							<progress id="progress" :value="uploadZula" max="100" ></progress>
						</section>
					</section>
				</div>
			</div>
		</div>
    	<section class="gallery" >
			<div class="container-fluid">
				<div class="row">
					<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6" v-for="(img, index) in allZulaImg" :key="index.id">
						<div class="preview">
							<img :src="img.src" >
							<div class="delete-btn">
								<button @click="deleteImageZula(img)"><trashIcon/></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
  	</section>
</template>

<script>
import trashIcon from '../../assets/icons/trash-alt-regular.svg'
import {mapGetters, mapActions} from 'vuex'

export default {
	name: 'Zula',
	components: {
		trashIcon
	},
	computed:{
    	...mapGetters(['allZulaImg', 'uploadZula',]),
  	},
	created (){
		if (this.getZulaImg.length < 1) {
			this.getZulaImg()
		}
	},
	methods: {
    	...mapActions(['getZulaImg', 'onUploadZula', 'deleteImageZula']),
    },
        
}
</script>

<style lang="scss">
@import "../../assets/scss/_variables";
@import "../../assets/scss/_mixins";

.edit {
	@include admin-edit;
}

.fileUpload {
	@include upload-btn;
}

.progres{
	@include progress-bar;
}


</style>