<template>
  	<section v-scrollanimation class="viewaction p-84" v-if="!allAction.length == 0">
      	<div class="container-fluid">
        	<div class="row">
          		<div class="col-lg-12">
					<h2>Akciové ponuky</h2>
          		</div>
				<div class="col-lg-12" v-for="(action, index) in allAction" :key="index.id">
					<div class="discount">
						<div class="discount-title">
							<h4>{{action.title}}</h4>
						</div>
						<div class="discount-description">
							<p>{{action.description}}</p>
						</div>
					</div>
				</div>
        	</div>
      	</div>
  	</section>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
	name: 'ViewAction',
	computed:{
    	...mapGetters(['allAction']),
  	},
	created (){
		if (this.getAction.length < 1) {
			this.getAction()
		}
	},
	methods: {
    	...mapActions(['getAction']),
    },
	
}
</script>

<style lang="scss">
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";

.viewaction{
	margin-top: 24px;
	h2{
		@include section-header;
	}
	.discount{
		.discount-title{
			margin: 24px 0 12px;
			h4{
				@include section-header;
				color: red;
				text-transform: unset;
			}
		}
		.discount-description{
			p{
				font-size: 18px;
			}
		}
	}
}

</style>