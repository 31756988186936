<template>
    <section class="header-top p-84">
        <div class="container-fluid">
            <div class="row align-items-center">
                    <div class="col-lg-6 col-md-8 col-sm-10">
                        <div class="contact">
                            <div class="phone">
                                <phoneIcon/>
                                <a href="tel:0911 249 267">0911 249 267</a>
                            </div>
                            <div class="mail" style="margin-left: 24px">
                                <mailIcon/>
                                <a href="mailto:gelkamsro@gmail.com">gelkamsro@gmail.com</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-4 col-sm-2">
                        <div class="login-btn">
                            <div class="login-icon" @click="show" v-if="!isLoggedIn">
                                <userIcon/>
                            </div>
                            <router-link v-if="isLoggedIn" to="/admin/uvod"><userconfigIcon/></router-link>
                            <button v-if="isLoggedIn" class="logout" @click="logout"><signoutIcon/></button>
                            <transition name="slide">
                                <section class="login" v-if="showLogin">
                                    <div class="close-btn" @click="close"> 
                                        <closeIcon/>
                                    </div>
                                    <div class="login-form" >
                                        <form>
                                            <h3>Prihlásiť sa</h3>
                                            <div class="inputs">
                                                <mailIcon/>
                                                <input type="text" id="email" placeholder="Email" v-model="email" required>
                                            </div>
                                            <br>
                                            <div class="inputs">
                                                <lockIcon/>
                                                <input type="password" id="password" placeholder="Heslo" v-model="password" required>
                                            </div>
                                            <button @click="login">Prihlásiť sa</button>
                                        </form>
                                    </div>
                                </section>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
    </section>
</template>

<script>
import phoneIcon from '../../assets/icons/phone-solid.svg'
import mailIcon from '../../assets/icons/envelope-regular.svg'
import userIcon from '../../assets/icons/user-solid.svg'
import userconfigIcon from '../../assets/icons/user-cog-solid.svg'
import signoutIcon from '../../assets/icons/sign-out-alt-solid.svg'
import closeIcon from '../../assets/icons/times-solid.svg'
import lockIcon from '../../assets/icons/lock-solid.svg'
import { mapActions, mapGetters } from 'vuex'
import firebase from 'firebase/app'
import Swal from 'sweetalert2'

export default {
    name: 'HeaderTop',
    components:{
        mailIcon, phoneIcon, userIcon, userconfigIcon, signoutIcon, closeIcon, lockIcon
    },
    data: () => {
        return {
            email: '',
            password: '',
            showLogin: false
        }
  },
  computed:{
    ...mapGetters(['isLoggedIn', 'currentUser'])
  },
  created(){
    this.current()
  },
  methods: {
    login(event){
			firebase.auth()
			.signInWithEmailAndPassword(this.email, this.password)
			.then(user => {
				Swal.fire({
				title: 'Úspech',
				text: `Používateľ ${this.email} prihlásený`,
				icon: 'success',
                timer: 2000
				})
			.then()
			setTimeout( ()=> this.$router.push({name: 'AdminUvod'}),
                this.showLogin = false, 2000)
			},
			err => {
				alert(err.messsage)
			})
			event.preventDefault()
		},

    ...mapActions(['current', 'logout']),

    show() {
      this.showLogin = !this.showLogin
    },

    close() {
      this.showLogin = false
		}
    
  }
}
</script>

<style lang="scss">
@import "../../assets/scss/_variables";
@import "../../assets/scss/_mixins";

.header-top{
    background: $gradient;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    .contact{
        text-align: left;
        @media #{$xs}{
            text-align: center;
        }
        .phone,.mail{
            display: inline-block;
            a,svg{
                color: $secondary !important;
            }
        }
        .phone,.mail{
            a{
                margin-left: 8px;
            }
        }
    }
    .login-btn{
        text-align: right;
        @media #{$xs}{
            text-align: center;
            margin-top: 12px;
        }
        .login-icon{
            position: relative;
            svg{
                color: $secondary;
                cursor: pointer;
                pointer-events: fill;
            }
        }
        .fas, .fa-user-cog{
            color: $secondary;
            margin-right: 8px;
        }
        .logout{
            border: none;
            outline: none;
            background: none;
            color: $secondary !important;
            padding: 0;
        }
        .login{
            position: absolute;
            top: 160%;
            right: 0;
            z-index: 999;
            display: inline-block;
            background: $white;
            padding: 16px 32px;
            border: 2px solid $primary;
            border-radius: 5px;
            @media #{$xs}{
                right: 50%;
                transform: translate(50%);
                padding: 16px 24px;
            }
            .close-btn{
                position: inherit;
                right: 32px;
                .fas,.fa-times{
                    font-size: 25px;
                    cursor: pointer;
                }
            }
            .login-form{
                form{
                    text-align: left;
                    .inputs{
                        color: $primary;
                        display: flex;
                        align-items: center;
                        svg{
                            font-size: 25px;
                            margin-right: 12px;
                            width: 20px;
                        }
                    }
                    h3{
                        color: $primary;
                        text-align: center;
                        font-size: 20px;
                        font-weight: 700;
                        margin-bottom: 24px !important;
                    }
                    input{
                        display: block;
                        width: 280px;
                        border: 1px solid $primary;
                        padding: 12px 0 12px 12px;
                        border-radius: 5px;
                        @media #{$very_small}{
                            width: 200px
                        }
                    }
                    button{
                        @include buttons;
                        margin-top: 12px;
                        margin-left: 32px;
                    }
                }
            }
        }
    }
}


// animations
.slide-enter {
  opacity: 0;
  transform: translateY(-10px);
  transition: 0.5s all ease;
}

.slide-enter-to {
  opacity: 1;
  transform: translateY(0px);
  transition: 0.5s all ease;
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(-10px);
  transition: 0.5s all ease;
}


</style>