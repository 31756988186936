<template>
  <section v-scrollanimation class="recent mt-68">
      <div class="container-fluid">
          <div class="row align-items-center">
              <div class="col-lg-6 col-md-6">
                  <div class="recent-works-info">
                        <h2>Posledné realizované práce</h2>
                        <router-link class="margin" to="/naseprace#realizacie">Pozrite si našu Galériu</router-link>
                    </div>
              </div>
              <div class="col-lg-6 col-md-6">
                  <div class="recent-works-img">
                    <agile :infinite="true" :autoplay="true" :navButtons="true" :autoplay-speed="5000" :dots="false" v-if="allPoslednepraceImg.length">
                        <div v-for="(img, index) in allPoslednepraceImg" :key="index.id">
                            <div class="slide">
                                <img :src="img.src">
                            </div>
                        </div>
                        <template slot="prevButton" name="prev"><leftIcon/></template>
    				    <template slot="nextButton" name="next"><rightIcon/></template>
                    </agile>
                </div>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
import leftIcon from '../assets/icons/angle-left-solid.svg'
import rightIcon from '../assets/icons/angle-right-solid.svg'
import {mapGetters, mapActions} from 'vuex'
export default {
    name: 'RecentWorks',
    components: {leftIcon, rightIcon,},
    computed:{
    	...mapGetters(['allPoslednepraceImg']),
  	},
	created (){
		if (this.getPoslednepraceImg.length < 1) {
			this.getPoslednepraceImg()
		}
	},
	methods: {
    	...mapActions(['getPoslednepraceImg']),
    },
}
</script>

<style lang="scss">
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";

.recent{
    background: $bg;
    position: relative;
    overflow: hidden;
    @include ripped;
    display: flex;
    @media #{$very_small}{
        display: block;
    }
    align-items: center;
    text-align: center;
    .container-fluid{
        padding-right: 0px;
        @media #{$very_small}{
            padding-right: 0px;
            padding-left: 0px;
        }
    }
    .recent-works-info{
        position: relative;
        z-index: 12;
        @media #{$very_small}{
            height: 150px;
            margin-top: 48px;
        }
        @media #{$xs}{
            height: auto;
        }
        h2{
            font-size: 26px;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 16px !important;
        }
        a{
            @include buttons;
            text-transform: unset;
            margin-top: 8px;  
            &:last-child{
                @media #{$xs}{
                    margin-bottom: 24px;
                }
            } 
        }
        .margin{
            margin-right:16px;
            @media #{$mobile}{
                margin-right:0px;
            }
        }
    }
    .recent-works-img{
        position: relative;
        margin-left: 15px;
        @media #{$very_small}{
            margin-left: 0px;
        }
        img{
            width: 100%;
            height: 389px;
            @media #{$very_small}{
                height: auto;
            }
        }
    }
    .agile__actions{
        .agile__nav-button{
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 2px solid $secondary;
            background: rgba($primary, 0.5);
            outline: none;
            svg{
                color: $secondary;
            }
            .fa-chevron-right{
                margin-left: 1px !important;
                margin-top: 4px !important;
            }
            .fa-chevron-left{
                margin-left: -1px !important;
                margin-top: 4px !important;
            }
        }
        .agile__nav-button--prev{
            position: absolute;
            bottom: 34px;
            left: 24px;
            z-index: 15;
        }
        .agile__nav-button--next{
            position: absolute;
            bottom: 34px;
            left: 78px;
            z-index: 15;
        }
    }
}
</style>