<template>
  <main>
    <section class="home">
      <agile :infinite="true" :autoplay="true" :navButtons="false" :autoplay-speed="5000" :dots="false">
        <div class="slide">
          <div class="overlay">
          <img src="../assets/img/urny.jpg" title="Urnové hroby" alt="Urnové hroby">
          </div>
        </div>
        <div class="slide">
          <div class="overlay">
          <img src="../assets/img/urny1.jpg" title="Urnové hroby" alt="Urnové hroby">
          </div>
        </div>
        <div class="slide">
          <div class="overlay">
          <img src="../assets/img/jedno.jpg" title="Jednopomníky" alt="Jednopomníky">
          </div>
        </div>
        <div class="slide">
          <div class="overlay">
          <img src="../assets/img/jedno1.jpg" title="Jednopomníky" alt="Jednopomníky">
          </div>
        </div>
        <div class="slide">
          <div class="overlay">
          <img src="../assets/img/dvoj.jpg" title="Dvojpomníky" alt="Dvojpomníky">
          </div>
        </div>
        <div class="slide">
          <div class="overlay">
          <img src="../assets/img/dvoj1.jpg" title="Dvojpomníky" alt="Dvojpomníky">
          </div>
        </div>
          
      </agile>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <section class="heading p-84">
              <h4>Vitajte na stránke kamenárstva Gelkam, s.r.o.</h4>
              <div class="title">
                <h1>Ponúkame:&nbsp;</h1>
                <vue-typed-js :loop="true" :typeSpeed="100" :fadeOut="true" :showCursor="false"  :strings="['Žulové urnové hroby', 'Jednopomníky', 'Dvojpomníky', 'Hroby na mieru', 'Stavebnú architektúru' ]">
                  <h1 class="typing"></h1>
                </vue-typed-js>
              </div>
              <router-link class="heading-btn" :to="{name: 'Contact'}">Kontaktujte nás a dohodnite si osobné stretnutie!</router-link>
            </section>
          </div>
        </div>
      </div>
    </section>
    <Aboutus/>
    <ViewAction/>
    <Offer/>
    <Feedback/>
    <RecentWorks/>

  </main>
</template>

<script>
import { VueAgile } from 'vue-agile'
import Aboutus from '../components/Aboutus-text'
import ViewAction from '../components/ViewAction'
import Offer from '../components/Offer'
import Feedback from '../components/Feedback'
import RecentWorks from '../components/RecentWorks'
export default {
  name: 'Home',
  components:{
    agile: VueAgile,
    Aboutus, ViewAction, Offer, Feedback, RecentWorks
  }
}
</script>

<style lang="scss">
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";

.home{
  position: relative;
  overflow: hidden;
  .agile{
    .slide{
      position: relative;
        @include ripped;
      .overlay{
        @include overlay;
        img{
          width: 100%;
          height: 500px;
        }
      }
    }
  }
  .col-lg-12{
    position: static;
  }
  .heading{
    color: $white;
    text-align: left;
    position: absolute !important;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    @media #{$very_small}{
        text-align: center;
        left: 0;
      }
    h4{
      font-size: 20px;
    }
    .title{
      h1, .typed-element{
        display: inline-block;
        @media #{$very_small}{
          display: block;
        }
      }
      h1{
        color: $secondary;
        margin-top: 20px !important;
        margin-bottom: 36px !important;
      }
      .typed-element{
        @media #{$very_small}{
          height: 100px;
        }
      }
    }
    .heading-btn{
      @include buttons;
      border: 1px solid $white;
      color: $white !important;
      @media #{$very_small}{
        display: inline-block;
        word-wrap: break-word;
        text-align: center;
      }
    }
  }
}
</style>
