//test environment
/*
export default{
	apiKey: "AIzaSyCwFv2p-xdkTh3MTIC3WKrw7XKVlmeRzxk",
    authDomain: "gelkam-84daa.firebaseapp.com",
    projectId: "gelkam-84daa",
    storageBucket: "gelkam-84daa.appspot.com",
    messagingSenderId: "567588985201",
    appId: "1:567588985201:web:15c4c0a3617a7b92383ed0"
}*/

// prod environment

export default{
    apiKey: "AIzaSyCv37_dCId20Yd_exRNHEbxifw2yfNStY0",
    authDomain: "gelkam-web.firebaseapp.com",
    projectId: "gelkam-web",
    storageBucket: "gelkam-web.appspot.com",
    messagingSenderId: "406000718614",
    appId: "1:406000718614:web:da979a5989f13b10847637"
}