<template>
  <section class="navigation p-84" >
      <div class="container-fluid">
        <div class="row">
            <nav class="col-lg-12">
              <ul class="nav-bar">
                <li>
                  <router-link :to="{name: 'Home'}">Domov</router-link>
                </li>
                <li>
                  <router-link :to="{name: 'About'}">O nás</router-link>
                </li>
                <li>
                  <router-link :to="{name: 'Products'}">Produkty</router-link>
                </li>
                <li>
                  <router-link :to="{name: 'Realisations'}">Naše práce</router-link>
                </li>
                <li>
                  <router-link :to="{name: 'Pricelist'}">Cenník</router-link>
                </li>
                <li>
                  <router-link :to="{name: 'Contact'}">Kontakt</router-link>
                </li>
              </ul>
            </nav>
        </div>
      </div>
      <Sidemenu/>
  </section>
</template>

<script>
import Sidemenu from './Sidemenu'
export default {
	name: 'Navigation',
  components: {
    Sidemenu,
  }
}
</script>

<style lang="scss">
@import "../../assets/scss/_variables";
@import "../../assets/scss/_mixins";

.navigation{
  padding-top: 12px !important;
  text-align: left;
  position: relative;
  z-index: 20;
    .nav-bar{
      animation: navigation 1.3s;
      @media #{$very_small}{
        display: none;
      }
      li{
        margin-left: 24px;
        &:first-child{
          margin-left: 0px;
        }
        @media #{$lg}{
          margin-left: 12px;
        }
        @media #{$md}{
          margin-left: 12px;
        }
        a{
          position: relative;
          font-size: 20px;
          font-weight: 700;
          &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-bottom: 3px solid $secondary;
            width: 0%;
            transition: 0.5s ease all;
          }
          &:hover:before{
            width: 100%;
          }
          &:focus:before{
            width: 100%;
          }
        }

      }
      .router-link-exact-active{
        color: $secondary !important;
        font-weight: 700;
      }
      @keyframes navigation {
        0%{
          transform: translateX(-100px);
          opacity: 0;
        }
        50%{
          opacity: 0;
        }
        75%{
          opacity: 1;
        }
        100%{
          transform: translateX(0px);
          opacity: 1;
        }
      }
    }
  }
</style>