import Vue from 'vue'
import db from '../../Firebase/firebaseInit'
import firebase from 'firebase/app'
import 'firebase/storage'
import Swal from 'sweetalert2'

	const state = {
		//show image
		jednopomnik: [], 
		//upload image
		jednopomnikImg: '',
		jednopomnikImageData: null,
		jednopomnikTime: null,
		uploadJednopomnik: 0,
	}
	
	const getters = {
		//show image
		allJednopomnikImg: state => state.jednopomnik,
		//upload image
		uploadJednopomnik: state => state.uploadJednopomnik,
	}
	
	const actions = {
		//show image
		getJednopomnikImg({ commit }){
			db.collection('jednopomnik')
				.orderBy('jednopomnikTime', 'desc')
				.onSnapshot(querySnapshot => {
					  const mappedResponse = [];
						querySnapshot.forEach(doc => {
							const response = {
								'id': doc.id,
								'jednopomnikTime': doc.data().jednopomnikTime,
								'src': doc.data().src,
							};
							mappedResponse.push(response);
					})
				commit('setjednopomnik', mappedResponse);
			})
		},
		//upload image
		onUploadJednopomnik({commit}, event){
			state.uploadJednopomnik=0;
			state.jednopomnikImageData = event.target.files[0];
			const storageRef = firebase.storage().ref().child('jednopomnik/' + `${state.jednopomnikImageData.name}`).put(state.jednopomnikImageData);
	
			storageRef.on(`state_changed`,snapshot=>{
				state.uploadJednopomnik = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
				}, error=>{console.log(error.message)},
				()=>{state.uploadJednopomnik=100;
				
				storageRef.snapshot.ref.getDownloadURL().then((url)=>{
					state.jednopomnikImg =url;
					db.collection('jednopomnik')
					.add({
						jednopomnikTime: state.jednopomnikTime = new Date().toLocaleString(),
						src: state.jednopomnikImg,
					})
					.then()
					.catch(error => console.log('error'))
					})
				}      
			)
			commit('uploadJednopomnik', storageRef)
		},
		//delete image
		deleteImageJednopomnik({commit}, doc){
			Swal.fire({
				title: 'Ste si istý?',
				text: "Zmeny už nebude možné vrátiť späť",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Áno, vymazať!',
				cancelButtonText: 'Zrušiť'
				})
				.then((result) => {
					if (result.isConfirmed) {
						let deleteItem = db.collection('jednopomnik').doc(doc.id).delete()
						let image = firebase.storage().refFromURL(doc.src).delete().then()
						commit('deleteJednopomnik', deleteItem, image)
						Swal.fire({
							title:'Vymazané!',
							text:'Váš súbor bol úspešne vymazaný!',
							icon:'success',
							timer: 2000
							}
						)
					}
					else if (result.dismiss === Swal.DismissReason.cancel) {
						Swal.fire({
							title:'Zrušené',
							text:'Váš súbor ostal v bezpečí!',
							icon:'error',
							timer: 1500
							})
					}
						
				})
				
				
			}
	}
	
	const mutations = {
		//show image
		setjednopomnik (state, jednopomnik){
			Vue.set(state, 'jednopomnik', jednopomnik)
		},
		//upload image
		uploadJednopomnik(state, img){
			Vue.set(state, 'img', img)
		},
		//delete image
		deleteJednopomnik(state, img){
			Vue.set(state, 'img', img)
		}
	}

export default {
	state,
	getters, 
	actions, 
	mutations
}