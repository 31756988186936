<template>
  <section v-scrollanimation class="offer mt-68">
        <div class="cemetery">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-12">
                        <div class="cemetery-info">
                            <h2>Cintorínska Architektúra</h2>
                            <p>Široký výber jednopomníkov, dvojpomníkov a žulových urnových hrobov.</p>
                            <p>Pozrite si našu ponuku:</p>
                            <router-link class="margin" to="/produkty/jednopomniky#jednopomniky">Jednopomníky</router-link>
                            <router-link class="margin" to="/produkty/dvojpomniky#dvojpomniky">Dvojpomníky</router-link>
                            <router-link class="margin" to="/produkty/zula#zula">Žulové hroby</router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cemetery-img">
                <img src="../assets/img/cintorin.jpg" alt="Cintorínska architektúra" title="Cintorínska architektúra">
            </div>
        </div>
        <div class="architecture">
            <div class="architecture-img">
                <img src="../assets/img/domacnost.jpg" alt="Stavebná architektúra" title="Stavebná architektúra">
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="architecture-info">
                            <h2>Stavebná architektúra</h2>
                            <p>Široký sortiment výrobkov do domácnosti podľa vašich predstáv a podľa vlastného návrhu.</p>
                            <router-link class="margin" to="/produkty/domacnost#domacnost">Pozrite si našu ponuku</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </section>
</template>

<script>
export default {
    name: 'Offer'
}
</script>

<style lang="scss">
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";

.offer{
    background: $bg;
    position: relative;
    @include ripped;
    .cemetery, .architecture{
        display: flex;
        @media #{$very_small}{
            display: block;
        }
        align-items: center;
        text-align: left;
        .cemetery-info{
            padding-left: 84px;
            @media #{$mobile}{
                text-align: center;
                padding-left: 0px;
            }
        }
        .cemetery-img, .architecture-img{
            flex: 0 0 50%;
            position: relative;
            &::before{
            content: '';
            background: rgba($primary, 0.2);
            position: absolute !important;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            }
            img{
                width: 100%;
                height: 389px;
                @media #{$very_small}{
                    height: auto;
                }
            }
        }
        .cemetery-info, .architecture-info{
            position: relative;
            z-index: 14;
            @media #{$very_small}{
                height: 250px;
                margin-top: 48px;
            }
            @media #{$xs}{
                height: auto;
            }
            h2{
                font-size: 26px;
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 16px !important;
            }
            p{
                font-size: 18px;
                margin-bottom: 16px !important;
            }
            a{
                @include buttons;
                text-transform: unset;
                margin-top: 8px;  
                &:last-child{
                    @media #{$xs}{
                        margin-bottom: 24px;
                    }
                }
            }
            .margin{
                margin-right:16px;
                @media #{$xs}{
                    display: block;
                    margin-right:0px;
                }
            }
        }
    }
    .architecture{
        text-align: left;
        .architecture-info{
            padding-right: 84px;
            padding-left: 15px;
            @media #{$mobile}{
                text-align: center;
                padding-right: 0px;
                padding-left: 0px;
            }
            a{
                @include buttons;
                text-transform: unset;
                @media #{$xs}{
                    margin-bottom: 48px !important;
                }
            }
        }
    }
}
</style>