import Vue from 'vue'
import db from '../../Firebase/firebaseInit'
import firebase from 'firebase/app'
import 'firebase/storage'
import Swal from 'sweetalert2'

	const state = {
		//show image
		namieru: [], 
		//upload image
		namieruImg: '',
		namieruImageData: null,
		namieruTime: null,
		uploadNamieru: 0,
	}
	
	const getters = {
		//show image
		allNamieruImg: state => state.namieru,
		//upload image
		uploadNamieru: state => state.uploadNamieru,
	}
	
	const actions = {
		//show image
		getNamieruImg({ commit }){
			db.collection('namieru')
				.orderBy('namieruTime', 'desc')
				.onSnapshot(querySnapshot => {
					  const mappedResponse = [];
						querySnapshot.forEach(doc => {
							const response = {
								'id': doc.id,
								'namieruTime': doc.data().namieruTime,
								'src': doc.data().src,
							};
							mappedResponse.push(response);
					})
				commit('setNamieru', mappedResponse);
			})
		},
		//upload image
		onUploadNamieru({commit}, event){
			state.uploadNamieru=0;
			state.namieruImageData = event.target.files[0];
			const storageRef = firebase.storage().ref().child('namieru/' + `${state.namieruImageData.name}`).put(state.namieruImageData);
			storageRef.on(`state_changed`,snapshot=>{
				state.uploadNamieru = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
				}, error=>{console.log(error.message)},
				()=>{state.uploadNamieru=100;
				
				storageRef.snapshot.ref.getDownloadURL().then((url)=>{
					state.namieruImg =url;
					db.collection('namieru')
					.add({
						namieruTime: state.namieruTime = new Date().toLocaleString(),
						src: state.namieruImg,
					})
					.then()
					.catch(error => console.log('error'))
					})
				}      
			)
			commit('uploadNamieru', storageRef)
		},
		//delete image
		deleteImageNamieru({commit}, doc){
			Swal.fire({
				title: 'Ste si istý?',
				text: "Zmeny už nebude možné vrátiť späť",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Áno, vymazať!',
				cancelButtonText: 'Zrušiť'
				})
				.then((result) => {
					if (result.isConfirmed) {
						let deleteItem = db.collection('namieru').doc(doc.id).delete()
						let image = firebase.storage().refFromURL(doc.src).delete().then()
						commit('deleteNamieru', deleteItem, image)
						Swal.fire({
							title:'Vymazané!',
							text:'Váš súbor bol úspešne vymazaný!',
							icon:'success',
							timer: 2000
							}
						)
					}
					else if (result.dismiss === Swal.DismissReason.cancel) {
						Swal.fire({
							title:'Zrušené',
							text:'Váš súbor ostal v bezpečí!',
							icon:'error',
							timer: 1500
							}
						)
					}
						
				})
				
				
			}
	}
	
	const mutations = {
		//show image
		setNamieru (state, namieru){
			Vue.set(state, 'namieru', namieru)
		},
		//upload image
		uploadNamieru(state, img){
			Vue.set(state, 'img', img)
		},
		//delete image
		deleteNamieru(state, img){
			Vue.set(state, 'img', img)
		}
	}

export default {
	state,
	getters, 
	actions, 
	mutations
}