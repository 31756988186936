<template>
	<main>
		<section class="layout">
			<div class="container-fluid">
				<div class="row">
					<div class="col-lg-12">
						<div class="admin-title">
							<h3>Pridať recenziu</h3>
						</div>
					</div>
					<div class="col-lg-12">
						<div class="add-form">
							<form @submit.prevent="saveFeedback">
								<div class="row">
									<div class="col-lg-12">
										<p>Slug (Povinné)</p>
										<label for="slug">
											<input id="slug" type="text" v-model="slug" required placeholder="Slug">
										</label>
										<p>Recenzia (Povinné)</p>
										<label for="feedback">
											<textarea id="feedback" type="text" v-model="feedback" required placeholder="Recenzia"></textarea>
										</label>
										<p>Meno (Povinné)</p>
										<label for="name">
											<input id="name" type="text" v-model="name" required placeholder="Meno">
										</label>
									</div>
								</div>
								<button type="submit" class="add-btn">Uverejniť</button>
								<router-link class="back-btn" :to="{name: 'Recenzie'}">Späť</router-link>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import db from '../../Firebase/firebaseInit'

export default {
	name: 'AddAction',
	data(){
		return {
			slug: null,
			time: null,
			name: null,
			feedback: null,
		}
	},
	
	methods:{
		saveFeedback(){
			db.collection('feedback')
			.add({
				slug: this.slug,
				time: this.time = new Date().toLocaleString(),
				name: this.name,
				feedback: this.feedback, 
			})
			.then(docRef => this.$router.push('/admin/recenzie'))
			.catch(error => console.log('error'))
		}
	}
}
</script>

<style lang="scss">
@import "../../assets/scss/_variables";
@import "../../assets/scss/_mixins";


</style>