<template>
	<section class="mobile-menu">
		<section class="sidemenu">
			<button class="sidemenu__mobilebtn" v-on:click="navOpen=!navOpen" v-bind:class="{active:navOpen}">
				<span class="top"></span>
				<span class="mid"></span>
				<span class="bottom"></span>
			</button>
			<transition name="translateX">
				<nav v-show="navOpen">
					<section class="user-info">
						<h3>Admin panel</h3>
						<p>Prihlásený používateľ:</p>
						<p v-if="isLoggedIn">{{currentUser}}</p>
					</section>
					<div class="sidemenu__wrapper">
						<ul class="sidemenu__mobilelist">
							<li>
								<router-link to="/admin/uvod" style="margin-left:0px !important">
									<i class="fas fa-pen"></i>
									<p>Užívateľská príručka</p>
								</router-link>
							</li>
							<li>
								<router-link to="/admin/akcia" style="margin-left:0px !important">
									<i class="fas fa-pen"></i>
									<p>Akciové ponuky</p>
								</router-link>
							</li>
							<li>
								<router-link to="/admin/upravit-zula" style="margin-left:0px !important">
									<i class="fas fa-pen"></i>
									<p>Žulové urnové hroby</p>
								</router-link>
							</li>
							<li>
								<router-link to="/admin/upravit-jednopomnik" style="margin-left:0px !important">
									<i class="fas fa-pen"></i>
									<p>Jednopomníky</p>
								</router-link>
							</li>
							<li>
								<router-link to="/admin/upravit-dvojpomnik" style="margin-left:0px !important">
									<i class="fas fa-pen"></i>
									<p>Dvojpomníky</p>
								</router-link>
							</li>
							<li>
								<router-link to="/admin/upravit-namieru" style="margin-left:0px !important">
									<i class="fas fa-pen"></i>
									<p>Hroby na mieru</p>
								</router-link>
							</li>
							<li>
								<router-link to="/admin/upravit-domacnost" style="margin-left:0px !important">
									<i class="fas fa-pen"></i>
									<p>Domácnosť</p>
								</router-link>
							</li>
							<li>
							<router-link to="/admin/recenzie" style="margin-left:0px !important">
								<penIcon/>
								<p>Recenzie</p>
							</router-link>
						</li>
						<li>
							<router-link to="/admin/posledne-prace" style="margin-left:0px !important">
								<penIcon/>
								<p>Posledné práce</p>
							</router-link>
						</li>
						</ul>
						<ul class="sidemenu__mobilelist side-nav">
							<li>
								<h5>Navigácia:</h5>
							</li>
							<li>
								<router-link :to="{name: 'Home'}">Domov</router-link>
							</li>
							<li>
								<router-link :to="{name: 'About'}">O nás</router-link>
							</li>
							<li>
								<router-link :to="{name: 'Products'}">Produkty</router-link>
							</li>
							<li>
								<router-link :to="{name: 'Realisations'}">Naše práce</router-link>
							</li>
							<li>
								<router-link :to="{name: 'Pricelist'}">Cenník</router-link>
							</li>
							<li>
								<router-link :to="{name: 'Contact'}">Kontakt</router-link>
							</li>
						</ul>
						<button class="logout" @click="logout"> Odhlásiť sa<i class="fas fa-sign-out-alt"></i></button>
					</div>
				</nav>
			</transition>
		</section>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import penIcon from '../../assets/icons/pen-solid.svg'
export default {
	name: 'AdminSidemenu',
	components:{
		penIcon
	},
	data: () => {
		return{
			navOpen: false,
		}
	},
	computed:{
    	...mapGetters(['isLoggedIn', 'currentUser'])
	},
	created(){
		this.current()
	},
	methods: {
		 ...mapActions(['current', 'logout']),
  },
  watch: {
    '$route' () {
      this.navOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";
@import "../../assets/scss/_mixins";

.mobile-menu{
	display: flex;
	justify-content: flex-end;
	align-items: center;
	text-align: left;
	@media #{$laptop}{
		display: none;
	}
	.sidemenu {
		.user-info{
			padding: 12px 0 0 12px;
			color: $white;
		}
		nav {
			overflow: scroll;
			width: 30%;
			height: 100%;
			background: linear-gradient(to top, $secondary, $primary);
			color: $secondary;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 5;
			@media #{$sm}{
				width: 40%;
			}
			@media #{$xs}{
				width: 68%;
			}
		}
		.sidemenu {
			&__mobilebtn {
				display: block;
				width: 50px;
				height: 50px;
				background: $secondary;
				border: none;
				position: relative;
				z-index: 100;
				appearance: none;
				cursor: pointer;
				outline: none;
				span {
					display: block;
					width: 20px;
					height: 2px;
					margin: auto;
					background: $primary;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					transition: all .4s ease;
					&.top {
						transform: translateY(-8px);
					}
					&.bottom {
						transform: translateY(8px);
					}
				}
				&.active{
					.top {
						transform: rotate(-45deg);
					}
					.mid{
						transform: translateX(-20px) rotate(360deg);
						opacity: 0;
					}
					.bottom {
						transform: rotate(45deg);
					}
				}
			}
			&__wrapper {
				height: 100%;
			}
			&__mobilelist {
				@include admin-side-list;
				.router-link-exact-active{
					background: lighten($primary, 10%);
				}
				li{
					&:hover{
						background: lighten($primary, 10%);
					}
					&:focus{
						background: lighten($primary, 10%);
					}
				}
			}
		}
	}
}
.translateX-enter{
	transform:translateX(-100px);
	opacity: 0;
}

.translateX-enter-active,.translateX-leave-active{
	transition:0.5s ease;
}

.translateX-leave-to{
	transform: translateX(-100px);
	opacity: 0;
}

.logout{
	@include buttons;
	margin: 0 0 12px 12px;
	background: $white;
	border: none;
	outline: none;
	svg{
		margin-left: 12px;
	}
	&:hover{
		border: none;
		background: $white;
		color: $secondary !important;
		transition: 0.35s ease;
	}
	&:focus{
		border: none;
		background: $white;
		color: $secondary !important;
		transition: 0.35s ease;
	}
}

</style>