import Vue from 'vue'
import db from '../../Firebase/firebaseInit'
import Swal from 'sweetalert2'

const state = {
	//show actions
	action: [], 
}

const getters = {
	//show actions
	allAction: state => state.action
}

const actions = {
	//show actions
	getAction({ commit }){
		db.collection('akcie')
			.orderBy('time', 'desc')
			.onSnapshot(querySnapshot => {
					const mappedResponse = [];
					querySnapshot.forEach(doc => {
						const response = {
							'id': doc.id,
							'slug': doc.data().slug,
							'time': doc.data().time,
							'title': doc.data().title,
							'description': doc.data().description,
						};
						mappedResponse.push(response);
				})
			commit('setAction', mappedResponse);
		})
	},
	//delete image
	deleteAction({commit}, doc){
		Swal.fire({
			title: 'Ste si istý?',
			text: "Zmeny už nebude možné vrátiť späť",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Áno, vymazať!',
			cancelButtonText: 'Zrušiť'
			})
			.then((result) => {
				if (result.isConfirmed) {
					let deleteItem = db.collection('akcie').doc(doc.id).delete()
					commit('deleteAction', deleteItem)
					Swal.fire({
						title:'Vymazané!',
						text:'Váš súbor bol úspešne vymazaný!',
						icon:'success',
						timer: 2000
						}
					)
				}
				else if (result.dismiss === Swal.DismissReason.cancel) {
					Swal.fire({
						title:'Zrušené',
						text:'Váš súbor ostal v bezpečí!',
						icon:'error',
						timer: 1500
						}
					)
				}
			})
		}
}

const mutations = {
	//show actions
	setAction (state, action){
		Vue.set(state, 'action', action)
	},
	//delete actions
	deleteAction(state, action){
		Vue.set(state, 'action', action)
	}
}

export default {
	state,
	getters, 
	actions, 
	mutations
}