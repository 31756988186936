import Vue from 'vue'
import db from '../../Firebase/firebaseInit'
import Swal from 'sweetalert2'

const state = {
	//show feddback
	feedback: [], 
}

const getters = {
	//show feddback
	allFeedback: state => state.feedback
}

const actions = {
	//show feedback
	getFeedback({ commit }){
		db.collection('feedback')
			.orderBy('time', 'desc')
			.onSnapshot(querySnapshot => {
					const mappedResponse = [];
					querySnapshot.forEach(doc => {
						const response = {
							'id': doc.id,
							'slug': doc.data().slug,
							'time': doc.data().time,
							'name': doc.data().name,
							'feedback':doc.data().feedback,
						};
						mappedResponse.push(response);
				})
			commit('setFeedback', mappedResponse);
		})
	},
	//delete feedback
	deleteFeedback({commit}, doc){
		Swal.fire({
			title: 'Ste si istý?',
			text: "Zmeny už nebude možné vrátiť späť",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Áno, vymazať!',
			cancelButtonText: 'Zrušiť'
			})
			.then((result) => {
				if (result.isConfirmed) {
					let deleteItem = db.collection('feedback').doc(doc.id).delete()
					commit('deleteAction', deleteItem)
					Swal.fire({
						title:'Vymazané!',
						text:'Váš súbor bol úspešne vymazaný!',
						icon:'success',
						timer: 2000
						}
					)
				}
				else if (result.dismiss === Swal.DismissReason.cancel) {
					Swal.fire({
						title:'Zrušené',
						text:'Váš súbor ostal v bezpečí!',
						icon:'error',
						timer: 1500
						}
					)
				}
			})
		}
}

const mutations = {
	//show actions
	setFeedback (state, feedback){
		Vue.set(state, 'feedback', feedback)
	},
	//delete actions
	deleteFeedback(state, feedback){
		Vue.set(state, 'feedback', feedback)
	}
}

export default {
	state,
	getters, 
	actions, 
	mutations
}