<template>
  <section id="dvojpomniky" class="product-item">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-lg-12">
          <div class="title">
            <h2>Dvojpomníky</h2>
            <router-link to="/produkty#products"><backIcon/> Späť na produkty</router-link>
          </div>
        </div>
        <div class="col-lg-12">
          <p>Základ je v rozmere od 250x250cm, v závislosti od požiadavky zákazníka.</p>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6" v-for="(img, index) in allDvojpomnikImg.slice(0, 5)" :key="index.id">
          <section class="gallery-item" @click="openGallery(index)">
            <img :src="img.src" alt="Dvojpomník" title="Dvojpomník" >
            <p>Ukážka</p>
          </section>
          <br>
        </div>
        <router-link class="gallery-btn" to="/naseprace#dvojpomnikgallery">Galéria</router-link>
      </div>
    </div>
    <LightBox
        ref="lightbox"
        :media="allDvojpomnikImg"
        :show-caption="true"
        :show-light-box="false"
      />
  </section>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import LightBox from 'vue-it-bigger'
import backIcon from '../../assets/icons/angle-double-left-solid.svg'
const media = []
export default {
	name: 'Dvojpomnik',
	components: {
    	LightBox, backIcon
  	},
	data() {
    	return{
			media,
		}
	},
	computed:{
    	...mapGetters(['allDvojpomnikImg']),
  	},
	created (){
		if (this.getDvojpomnikImg.length < 1) {
			this.getDvojpomnikImg()
		}
	},
	methods: {
    	...mapActions(['getDvojpomnikImg']),
		openGallery(index) {
			this.$refs.lightbox.showImage(index)
		}
  	}
}
</script>

<style lang='scss'>
@import "../../assets/scss/_variables";
@import "../../assets/scss/_mixins";
@import "../../assets/scss/_lightbox";
@import "../../assets/scss/_productitem";

</style>