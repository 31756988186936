<template>
  	<section class="layout">
		<div class="container-fluid">
			<div class="row">
				<div class="col-lg-12">
					<section class="edit-title">
						<h2>Posledné práce</h2>
					</section>
					<section class="form">
						<form>
							<div class="fileUpload">
								<input type="file" class="upload" required ref="imgName" @change="onUploadPosledneprace">
								<span>Nahrať fotku</span>
							</div>
						</form>
						<section class="progres">
							<p>Nahrávam: {{uploadPosledneprace.toFixed()+"%"}}</p>
							<progress id="progress" :value="uploadPosledneprace" max="100" ></progress>
						</section>
					</section>
				</div>
			</div>
		</div>
    	<section class="gallery" >
			<div class="container-fluid">
				<div class="row">
					<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6" v-for="(img, index) in allPoslednepraceImg" :key="index.id">
						<div class="preview">
							<img :src="img.src">
							<div class="delete-btn">
								<button @click="deleteImagePosledneprace(img)"><trashIcon/></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
  	</section>
</template>

<script>
import trashIcon from '../../assets/icons/trash-alt-regular.svg'
import {mapGetters, mapActions} from 'vuex'

export default {
	name: 'PoslednePrace',
	components: {
		trashIcon
	},
	computed:{
    	...mapGetters(['allPoslednepraceImg', 'uploadPosledneprace',]),
  	},
	created (){
		if (this.getPoslednepraceImg.length < 1) {
			this.getPoslednepraceImg()
		}
	},
	methods: {
    	...mapActions(['getPoslednepraceImg', 'onUploadPosledneprace', 'deleteImagePosledneprace']),
    },
        
}
</script>

<style lang="scss">
@import "../../assets/scss/_variables";
@import "../../assets/scss/_mixins";

.edit {
	@include admin-edit;
}

.fileUpload {
	@include upload-btn;
}

.progres{
	@include progress-bar;
}


</style>