import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/app'
import Home from '../views/Home.vue'
import Admin from '../views/Admin'
import Granite from '../views/Children/Granite'
import Jednopomnik from '../views/Children/Jednopomnik'
import Dvojpomnik from '../views/Children/Dvojpomnik'
import Namieru from '../views/Children/Namieru'
import Domacnost from '../views/Children/Domacnost'
import UpravitZula from '../components/admin/UpravitZula'
import AdminUvod from '../components/admin/AdminUvod'
import UpravitJednopomnik from '../components/admin/UpravitJednopomnik'
import UpravitDvojpomnik from '../components/admin/UpravitDvojpomnik'
import UpravitNamieru from '../components/admin/UpravitNamieru'
import UpravitDomacnost from '../components/admin/UpravitDomacnost'
import Akcia from '../components/admin/Akcia'
import PridatAkcia from '../components/admin/PridatAkcia'
import UpravitAkcia from '../components/admin/UpravitAkcia'
import Recenzie from '../components/admin/Recenzie'
import PridatRecenziu from '../components/admin/PridatRecenziu'
import UpravitRecenziu from '../components/admin/UpravitRecenziu'
import PoslednePrace from '../components/admin/PoslednePrace'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Gelkam, s.r.o.', },
  },
  {
    path: '/onas',
    name: 'About',
    component: () => import(/* webpackChunkName: "About" */ '../views/About.vue'),
    meta: { title: 'Gelkam | O nás'},
  },
  {
    path: '/produkty',
    name: 'Products',
    component: () => import(/* webpackChunkName: "Products" */ '../views/Products.vue'),
    meta: { title: 'Gelkam | Produkty'},
    children: [
      { 
        path: 'zula', 
        component: Granite,
        meta:{
          title: 'Gelkam | Produkty - Žulové urny'} 
      },
      { 
        path: 'jednopomniky', 
        component: Jednopomnik, 
        meta:{
          title: 'Gelkam | Produkty - Jednopomníky'} 
      },
      { 
        path: 'dvojpomniky', 
        component: Dvojpomnik,
        meta:{
          title: 'Gelkam | Produkty - Dvojpomníky'} 
      },
      { 
        path: 'namieru', 
        component: Namieru, 
        meta:{
          title: 'Gelkam | Produkty - Hroby na mieru'} 
      },
      { 
        path: 'domacnost', 
        component: Domacnost, 
        meta:{
          title: 'Gelkam | Produkty - Domácnosť'} 
      },
    ],
  },
  {
    path: '/naseprace',
    name: 'Realisations',
    component: () => import(/* webpackChunkName: "Realisations" */ '../views/Realisations.vue'),
    meta: { title: 'Gelkam | Naše práce'},
  },
  
  {
    path: '/cennik',
    name: 'Pricelist',
    component: () => import(/* webpackChunkName: "Pricelist" */ '../views/Pricelist.vue'),
    meta: { title: 'Gelkam | Cenník'},
  },
  {
    path: '/kontakt',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue'),
    meta: { title: 'Gelkam | Kontakt'},
  },
  
  //add, edit, admin
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAuth: true,
      title: 'Gelkam | Admin'
    },
    children: [
      { 
        path: 'uvod', 
        name: 'AdminUvod',
        component: AdminUvod,
        meta:{
          title: 'Gelkam | Admin úvod',
        } 
      },
      { 
        path: 'akcia', 
        name: 'Akcia',
        component: Akcia,
        meta:{
          title: 'Gelkam | Akciové ponuky'
        },
      },
      {
        path: 'pridat-akcia', 
        name: 'PridatAkcia',
        component: PridatAkcia,
        meta:{
        title: 'Gelkam | Pridať akciové ponuky'},
      },
      {
        path: 'upravit-akcia/:id', 
        name: 'UpravitAkcia',
        component: UpravitAkcia,
        meta:{
        title: 'Gelkam | Upraviť akciové ponuky'},
      },
      { 
        path: 'upravit-zula', 
        name: 'UpravitZula',
        component: UpravitZula,
        meta:{
          title: 'Gelkam | Upraviť žulové urny'} 
      },
      { 
        path: 'upravit-jednopomnik', 
        name: 'UpravitJednopomnik',
        component: UpravitJednopomnik,
        meta:{
          title: 'Gelkam | Upraviť jednopomník'} 
      },
      { 
        path: 'upravit-dvojpomnik', 
        name: 'UpravitDvojpomnik',
        component: UpravitDvojpomnik,
        meta:{
          title: 'Gelkam | Upraviť dvojpomník'} 
      },
      { 
        path: 'upravit-namieru', 
        name: 'UpravitNamieru',
        component: UpravitNamieru,
        meta:{
          title: 'Gelkam | Upraviť na mieru'} 
      },
      { 
        path: 'upravit-domacnost', 
        name: 'UpravitDomacnost',
        component: UpravitDomacnost,
        meta:{
          title: 'Gelkam | Upraviť domácnosť'} 
      },
      { 
        path: 'recenzie', 
        name: 'Recenzie',
        component: Recenzie,
        meta:{
          title: 'Gelkam | Recenzie'} 
      },
      { 
        path: 'pridat-recenziu', 
        name: 'PridatRecenziu',
        component: PridatRecenziu,
        meta:{
          title: 'Gelkam | Pridať recenziu'} 
      },
      { 
        path: 'upravit-recenziu', 
        name: 'UpravitRecenziu',
        component: UpravitRecenziu,
        meta:{
          title: 'Gelkam | Upraviť recenziu'} 
      },
      { 
        path: 'posledne-prace', 
        name: 'PoslednePrace',
        component: PoslednePrace,
        meta:{
          title: 'Gelkam | Posledné práce'} 
      },
    ],
  },
  {
    //404 page
    path: '/*',
    name: 'Not-found',
    meta: { title: '404 | Stránka nenájdená'},
    component: () => 
      import(/* webpackChunkName: "404" */ '../views/Not-found.vue')
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  //Scroll to top
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
})



//Dynamically changig document title
router.beforeEach((to, from, next)=> {
  document.title = to.meta.title
  next()
});


//nav guards
router.beforeEach((to, from, next) => {
  //check for requireAuth guard
  if(to.matched.some(record => record.meta.requiresAuth)){
    //check if not logged in
    if(!firebase.auth().currentUser){
      //go to login 
      next({
        path: '/',
        query: {
          redirect: to.fullPath 
        }
      })
    }
    else{
      //proceed to route
      next()
    }
  }else if (to.matched.some(record => record.meta.requiresGuest )){
    if(firebase.auth().currentUser){
      
      //go to admin 
      next({
        path: '/admin/uvod',
        query: {
          redirect: to.fullPath 
        }
      })
    }
  }
  else{
    //proceed to route
    next()
  }
})

export default router
