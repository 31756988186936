import Vue from 'vue'
import db from '../../Firebase/firebaseInit'
import firebase from 'firebase/app'
import 'firebase/storage'
import Swal from 'sweetalert2'

	const state = {
		//show image
		domacnost: [], 
		//upload image
		domacnostImg: '',
		domacnostImageData: null,
		domacnostTime: null,
		uploadDomacnost: 0,
	}
	
	const getters = {
		//show image
		allDomacnostImg: state => state.domacnost,
		//upload image
		uploadDomacnost: state => state.uploadDomacnost,
	}
	
	const actions = {
		//show image
		getDomacnostImg({ commit }){
			db.collection('domacnost')
				.orderBy('domacnostTime', 'desc')
				.onSnapshot(querySnapshot => {
					  const mappedResponse = [];
						querySnapshot.forEach(doc => {
							const response = {
								'id': doc.id,
								'domacnostTime': doc.data().domacnostTime,
								'src': doc.data().src,
							};
							mappedResponse.push(response);
					})
				commit('setDomacnost', mappedResponse);
			})
		},
		//upload image
		onUploadDomacnost({commit}, event){
			state.uploadDomacnost=0;
			state.domacnostImageData = event.target.files[0];
			const storageRef = firebase.storage().ref().child('domacnost/' + `${state.domacnostImageData.name}`).put(state.domacnostImageData);
			storageRef.on(`state_changed`,snapshot=>{
				state.uploadDomacnost = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
				}, error=>{console.log(error.message)},
				()=>{state.uploadDomacnost=100;
				
				storageRef.snapshot.ref.getDownloadURL().then((url)=>{
					state.domacnostImg =url;
					db.collection('domacnost')
					.add({
						domacnostTime: state.domacnostTime = new Date().toLocaleString(),
						src: state.domacnostImg,
					})
					.then()
					.catch(error => console.log('error'))
					})
				}      
			)
			commit('uploadDomacnost', storageRef)
		},
		//delete image
		deleteImageDomacnost({commit}, doc){
			Swal.fire({
				title: 'Ste si istý?',
				text: "Zmeny už nebude možné vrátiť späť",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Áno, vymazať!',
				cancelButtonText: 'Zrušiť'
				})
				.then((result) => {
					if (result.isConfirmed) {
						let deleteItem = db.collection('domacnost').doc(doc.id).delete()
						let image = firebase.storage().refFromURL(doc.src).delete().then()
						commit('deleteDomacnost', deleteItem, image)
						Swal.fire({
							title:'Vymazané!',
							text:'Váš súbor bol úspešne vymazaný!',
							icon:'success',
							timer: 2000
							}
						)
					}
					else if (result.dismiss === Swal.DismissReason.cancel) {
						Swal.fire({
							title:'Zrušené',
							text:'Váš súbor ostal v bezpečí!',
							icon:'error',
							timer: 1500
							}
						)
					}
						
				})
				
				
			}
	}
	
	const mutations = {
		//show image
		setDomacnost (state, domacnost){
			Vue.set(state, 'domacnost', domacnost)
		},
		//upload image
		uploadDomacnost(state, img){
			Vue.set(state, 'img', img)
		},
		//delete image
		deleteDomacnost(state, img){
			Vue.set(state, 'img', img)
		}
	}

export default {
	state,
	getters, 
	actions, 
	mutations
}