<template>
  <main>
		<section class="layout">
			<div class="container-fluid">
				<div class="row">
					<div class="col-lg-12">
						<div class="admin-title">
							<h3>Upraviť recenzie</h3>
						</div>
					</div>
					<div class="col-lg-12">
						<div class="add-form">
							<form @submit.prevent="updateFeedback">
								<div class="row">
									<div class="col-lg-12">
										<p>Slug</p>
										<label for="slug">
											<input id="slug" type="text" v-model="slug" required placeholder="Slug">
										</label>
										<p>Recenzia</p>
										<label for="feedback">
											<textarea id="feedback" type="text" v-model="feedback" required placeholder="Recenzia"></textarea>
										</label>
										<p>Meno</p>
										<label for="name">
											<input id="name" type="text" v-model="name" required placeholder="Meno">
										</label>
									</div>
								</div>
								<button type="submit" class="add-btn">Upraviť</button>
								<router-link class="back-btn" :to="{name: 'Recenzie'}">Späť</router-link>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import db from '../../Firebase/firebaseInit'
export default {
	name: 'Edit',
	data(){
		return {
			slug: null,
			name: null,
			feedback: null,
		}
	},
	 beforeRouteEnter(to, from, next){
		db.collection('feedback')
		.where('slug', '==', to.params.id)
		.get()
		.then(querySnaphost =>{
				querySnaphost.forEach(doc => {
					next(vm =>{
						vm.slug = doc.data().slug
						vm.name = doc.data().name
						vm.feedback = doc.data().feedback
					})
				})
			})
	},
	watch: {
		'$route':'fetchData'
	},
	methods: {
		fetchData (){
			db.collection('feedback')
			.where('slug', '==', this.$route.params.id)
			.get()
			.then(querySnaphost => {
				querySnaphost.forEach(doc => {
					this.slug =  doc.data().slug
					this.name =  doc.data().name
					this.feedback =  doc.data().feedback
				})
			})
		},
		updateFeedback(){
			db.collection('feedback')
			.where('slug', '==', this.$route.params.id)
			.get()
			.then(querySnaphost => {
				querySnaphost.forEach(doc => {
					doc.ref.update({
						slug: this.slug,
						name: this.name,
						feedback: this.feedback,
					})
					.then(() => {
						this.$router.push('/admin/recenzie')
					})
				})
			})
		},
	}
}
</script>

<style lang="scss">
@import "../../assets/scss/_variables";
@import "../../assets/scss/_mixins";

//style setting are located in ./components/PridatAkcia.vue

</style>