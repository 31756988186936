<template>
    <header>
        <HeaderTop/>
        <HeaderBottom/>
        <Navigation/>
    </header>
  
</template>

<script>
import HeaderTop from './Header-top'
import HeaderBottom from './Header-bottom'
import Navigation from './Navigation'
export default {
    name: 'Header',
    components: {
        HeaderTop, HeaderBottom, Navigation,
    }
}
</script>

<style lang="scss">
@import "../../assets/scss/_variables";
@import "../../assets/scss/_mixins";



</style>