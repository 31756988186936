<template>
  <section id="namieru" class="product-item">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-lg-12">
          <div class="title">
            <h2>Pomníky Na mieru</h2>
            <router-link to="/produkty#products"><backIcon/> Späť na produkty</router-link>
          </div>
        </div>
        <div class="col-lg-12">
          <p>Ide o  jednopomníky a dvojpomníky podľa požiadaviek zákazníka, kde sa už nepochováva. Štandardne je základ 100x200cm, hrúbka materiálu 3cm. Nápisné tabule sú v štandardnej veľkosti. Pre viac info nás
            <router-link :to="{name: 'Contact'}">kontaktujte.</router-link> 
          </p>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6" v-for="(img, index) in allNamieruImg.slice(0, 5)" :key="index.id">
          <section class="gallery-item" @click="openGallery(index)">
            <img :src="img.src" alt="Pomníky na mieru" title="Pomníky na mieru" >
            <p>Ukážka</p>
          </section>
          <br>
        </div>
        <router-link class="gallery-btn" to="/naseprace#namierugallery">Galéria</router-link>
      </div>
    </div>
    <LightBox
        ref="lightbox"
        :media="allNamieruImg"
        :show-caption="true"
        :show-light-box="false"
      />
  </section>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import LightBox from 'vue-it-bigger'
import backIcon from '../../assets/icons/angle-double-left-solid.svg'
const media = []
export default {
	name: 'Namieru',
	components: {
    	LightBox, backIcon
  	},
	data() {
    	return{
			media,
		}
	},
	computed:{
    	...mapGetters(['allNamieruImg']),
  	},
	created (){
		if (this.getNamieruImg.length < 1) {
			this.getNamieruImg()
		}
	},
	methods: {
    	...mapActions(['getNamieruImg']),
		openGallery(index) {
			this.$refs.lightbox.showImage(index)
		}
  	}
}
</script>

<style lang='scss'>
@import "../../assets/scss/_variables";
@import "../../assets/scss/_mixins";
@import "../../assets/scss/_lightbox";
@import "../../assets/scss/_productitem";

#namieru{
  p{
    a{
      color: $secondary !important;
      font-weight: 700;
    }
  }
}
</style>