<template>
  	<section class="layout">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <section class="edit-title">
              <h2>Recenzie</h2>
			  <router-link  class="add-btn" to="/admin/pridat-recenziu">Pridať recenziu</router-link>
			  <router-view/>
            </section>
          </div>
			<section class="container-fluid">
				<div class="row">
					<div class="col-lg-12" v-for="(feedback, index) in allFeedback" :key="index.id">
						<div class="feedback">
							<div class="feedback-description">
								<h6>{{feedback.feedback}}</h6>
							</div>
							<div class="feedback-name">
								<p>{{feedback.name}}</p>	
							</div>	
							<div class="controls">
								<div class="edit-btn">
									<router-link  :to="{name: 'UpravitRecenziu', params: {id: feedback.slug}} "><penIcon/></router-link>
								</div>
								<div class="delete-btn">
									<button @click="deleteFeedback(feedback)"><trashIcon/></button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
        </div>
      </div>
  	</section>
</template>

<script>
import penIcon from '../../assets/icons/pen-solid.svg'
import trashIcon from '../../assets/icons/trash-alt-regular.svg'
import {mapGetters, mapActions} from 'vuex'
export default {
	name: 'Akcia',
	components: {
		penIcon, trashIcon
	},
	computed:{
    	...mapGetters(['allFeedback']),
  	},
	created (){
		if (this.getFeedback.length < 1) {
			this.getFeedback()
		}
	},
	methods: {
    	...mapActions(['getFeedback', 'deleteFeedback']),
    },
	
}
</script>

<style lang="scss">
@import "../../assets/scss/_variables";
@import "../../assets/scss/_mixins";
@import "../../assets/scss/_adminlayout";

.layout{
	.feedback{
		border: 1px solid $secondary;
		border-radius: 5px;
		padding: 24px;
		text-align: center;
		margin-bottom: 24px;
		.feedback-title{
			h6{
				@include section-header;
				color: red;
				margin-bottom: 12px !important;
			}
		}
		.feedback-description{
			h6{
				margin-bottom: 12px !important;
			}
		}
		.feedback-name{
			margin-bottom: 24px !important;
		}
		.controls{
			@include controls;
		}		
	}
}

</style>