<template>
	<main>
		<section class="layout">
			<div class="container-fluid">
				<div class="row">
					<div class="col-lg-12">
						<div class="admin-title">
							<h3>Pridať akciu</h3>
						</div>
					</div>
					<div class="col-lg-12">
						<div class="add-form">
							<form @submit.prevent="saveAction">
								<div class="row">
									<div class="col-lg-12">
										<p>Slug (Povinné)</p>
										<label for="slug">
											<input id="slug" type="text" v-model="slug" required placeholder="Slug">
										</label>
										<p>Názov akcie (Nepovinné)</p>
										<label for="title">
											<input id="title" type="text" v-model="title"  placeholder="Názov akcie">
										</label>
										<p>Popis (Nepovinné)</p>
										<label for="description">
											<textarea id="description" type="text" v-model="description"  placeholder="Popis"></textarea>
										</label>
									</div>
								</div>
								<button type="submit" class="add-btn">Uverejniť</button>
								<router-link class="back-btn" :to="{name: 'Akcia'}">Späť</router-link>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import db from '../../Firebase/firebaseInit'

export default {
	name: 'AddAction',
	data(){
		return {
			slug: null,
			time: null,
			title: null,
      		description: null
		}
	},
	
	methods:{
		saveAction(){
			db.collection('akcie')
			.add({
				slug: this.slug,
				time: this.time = new Date().toLocaleString(),
				title: this.title, 
				description: this.description, 
			})
			.then(docRef => this.$router.push('/admin/akcia'))
			.catch(error => console.log('error'))
		}
	}
}
</script>

<style lang="scss">
@import "../../assets/scss/_variables";
@import "../../assets/scss/_mixins";

.layout{
	.add-form{
		position: relative;
		z-index: 2;
		border: 1px solid $secondary;
		margin-top: 24px;
		background: $white;
		color: $primary !important;
		padding: 24px;
		text-align: left;
		@media #{$very_small}{
			padding: 24px 8px;
		}
		p{
			padding-left: 12px;
		}
		label{
			width: 100%;
			input, textarea{
				width: 100%;
				border: 1px solid $secondary;
				padding: 12px 0 12px 12px;
				border-radius: 5px;
			}
		}
		.add-btn{
			@include buttons;
			margin-top: 24px
		}
		.back-btn{
			@include buttons;
			background: #B3B3B3;
			color: $white !important;
			margin-left: 8px;
			border: 1px solid #B3B3B3;
		}
	}
}
</style>