import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.min.css'
import './Firebase/firebaseInit'
import firebase from 'firebase/app'
import 'firebase/auth'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import VueAgile from 'vue-agile'
import Vuelidate from 'vuelidate';
import VueTypedJs from 'vue-typed-js';
import ScrollAnimation from './directives/scrollanimation'


Vue.use(VueAgile)
Vue.use(Vuelidate);
Vue.use(VueTypedJs);
Vue.directive('scrollanimation', ScrollAnimation);
Vue.config.productionTip = false

let app = '';
firebase.auth().onAuthStateChanged(() => {
  if(!app){
    app = new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app')
  }
})