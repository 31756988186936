import Vue from 'vue'
import db from '../../Firebase/firebaseInit'
import firebase from 'firebase/app'
import 'firebase/storage'
import Swal from 'sweetalert2'

	const state = {
		//show image
		posledneprace: [], 
		//upload image
		poslednepraceImg: '',
		poslednepraceImageData: null,
		poslednepraceTime: null,
		uploadPosledneprace: 0,
	}
	
	const getters = {
		//show image
		allPoslednepraceImg: state => state.posledneprace,
		//upload image
		uploadPosledneprace: state => state.uploadPosledneprace,
	}
	
	const actions = {
		//show image
		getPoslednepraceImg({ commit }){
			db.collection('posledneprace')
				.orderBy('poslednepraceTime', 'desc')
				.onSnapshot(querySnapshot => {
					  const mappedResponse = [];
						querySnapshot.forEach(doc => {
							const response = {
								'id': doc.id,
								'poslednepraceTime': doc.data().poslednepraceTime,
								'src': doc.data().src,
							};
							mappedResponse.push(response);
					})
				commit('setPosledneprace', mappedResponse);
			})
		},
		//upload image
		onUploadPosledneprace({commit}, event){
			state.uploadPosledneprace=0;
			state.poslednepraceImageData = event.target.files[0];
			const storageRef = firebase.storage().ref().child('posledne/' + `${state.poslednepraceImageData.name}`).put(state.poslednepraceImageData);
			storageRef.on(`state_changed`,snapshot=>{
				state.uploadPosledneprace = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
				}, error=>{console.log(error.message)},
				()=>{state.uploadPosledneprace=100;
				
				storageRef.snapshot.ref.getDownloadURL().then((url)=>{
					state.poslednepraceImg =url;
					db.collection('posledneprace')
					.add({
						poslednepraceTime: state.poslednepraceTime = new Date().toLocaleString(),
						src: state.poslednepraceImg,
					})
					.then()
					.catch(error => console.log('error'))
					})
				}      
			)
			commit('uploadPosledneprace', storageRef)
		},
		//delete image
		deleteImagePosledneprace({commit}, doc){
			Swal.fire({
				title: 'Ste si istý?',
				text: "Zmeny už nebude možné vrátiť späť",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Áno, vymazať!',
				cancelButtonText: 'Zrušiť'
				})
				.then((result) => {
					if (result.isConfirmed) {
						let deleteItem = db.collection('posledneprace').doc(doc.id).delete()
						let image = firebase.storage().refFromURL(doc.src).delete().then()
						commit('deletePosledneprace', deleteItem, image)
						Swal.fire({
							title:'Vymazané!',
							text:'Váš súbor bol úspešne vymazaný!',
							icon:'success',
							timer: 2000
							}
						)
					}
					else if (result.dismiss === Swal.DismissReason.cancel) {
						Swal.fire({
							title:'Zrušené',
							text:'Váš súbor ostal v bezpečí!',
							icon:'error',
							timer: 1500
							}
						)
					}
						
				})
				
				
			}
	}
	
	const mutations = {
		//show image
		setPosledneprace (state, posledneprace){
			Vue.set(state, 'posledneprace', posledneprace)
		},
		//upload image
		uploadPosledneprace(state, img){
			Vue.set(state, 'img', img)
		},
		//delete image
		deletePosledneprace(state, img){
			Vue.set(state, 'img', img)
		}
	}

export default {
	state,
	getters, 
	actions, 
	mutations
}