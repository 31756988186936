import Vue from 'vue'
import Vuex from 'vuex'
import zula from './modules/zula'
import akcia from './modules/akcia'
import jednopomnik from './modules/jednopomnik'
import dvojpomnik from './modules/dvojpomnik'
import namieru from './modules/namieru'
import domacnost from './modules/domacnost'
import logout from './modules/logout'
import recenzie from './modules/recenzie'
import posledneprace from './modules/posledneprace'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    zula,
    akcia,
    jednopomnik,
    dvojpomnik,
    namieru,
    domacnost,
    logout,
    recenzie,
    posledneprace
  }
})
