import Vue from 'vue'
import db from '../../Firebase/firebaseInit'
import firebase from 'firebase/app'
import 'firebase/storage'
import Swal from 'sweetalert2'

	const state = {
		//show image
		dvojpomnik: [], 
		//upload image
		dvojpomnikImg: '',
		dvojpomnikImageData: null,
		dvojpomnikTime: null,
		uploadDvojpomnik: 0,
	}
	
	const getters = {
		//show image
		allDvojpomnikImg: state => state.dvojpomnik,
		//upload image
		uploadDvojpomnik: state => state.uploadDvojpomnik,
	}
	
	const actions = {
		//show image
		getDvojpomnikImg({ commit }){
			db.collection('dvojpomnik')
				.orderBy('dvojpomnikTime', 'desc')
				.onSnapshot(querySnapshot => {
					  const mappedResponse = [];
						querySnapshot.forEach(doc => {
							const response = {
								'id': doc.id,
								'dvojpomnikTime': doc.data().dvojpomnikTime,
								'src': doc.data().src,
							};
							mappedResponse.push(response);
					})
				commit('setDvojpomnik', mappedResponse);
			})
		},
		//upload image
		onUploadDvojpomnik({commit}, event){
			state.uploadDvojpomnik=0;
			state.dvojpomnikImageData = event.target.files[0];
			const storageRef = firebase.storage().ref().child('dvojpomnik/' + `${state.dvojpomnikImageData.name}`).put(state.dvojpomnikImageData);
			storageRef.on(`state_changed`,snapshot=>{
				state.uploadDvojpomnik = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
				}, error=>{console.log(error.message)},
				()=>{state.uploadDvojpomnik=100;
				
				storageRef.snapshot.ref.getDownloadURL().then((url)=>{
					state.dvojpomnikImg =url;
					db.collection('dvojpomnik')
					.add({
						dvojpomnikTime: state.dvojpomnikTime = new Date().toLocaleString(),
						src: state.dvojpomnikImg,
					})
					.then()
					.catch(error => console.log('error'))
					})
				}      
			)
			commit('uploadDvojpomnik', storageRef)
		},
		//delete image
		deleteImageDvojpomnik({commit}, doc){
			Swal.fire({
				title: 'Ste si istý?',
				text: "Zmeny už nebude možné vrátiť späť",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Áno, vymazať!',
				cancelButtonText: 'Zrušiť'
				})
				.then((result) => {
					if (result.isConfirmed) {
						let deleteItem = db.collection('dvojpomnik').doc(doc.id).delete()
						let image = firebase.storage().refFromURL(doc.src).delete().then()
						commit('deleteDvojpomnik', deleteItem, image)
						Swal.fire({
							title:'Vymazané!',
							text:'Váš súbor bol úspešne vymazaný!',
							icon:'success',
							timer: 2000
							}
						)
					}
					else if (result.dismiss === Swal.DismissReason.cancel) {
						Swal.fire({
							title:'Zrušené',
							text:'Váš súbor ostal v bezpečí!',
							icon:'error',
							timer: 1500
							}
						)
					}
						
				})
				
				
			}
	}
	
	const mutations = {
		//show image
		setDvojpomnik (state, dvojpomnik){
			Vue.set(state, 'dvojpomnik', dvojpomnik)
		},
		//upload image
		uploadDvojpomnik(state, img){
			Vue.set(state, 'img', img)
		},
		//delete image
		deleteDvojpomnik(state, img){
			Vue.set(state, 'img', img)
		}
	}

export default {
	state,
	getters, 
	actions, 
	mutations
}